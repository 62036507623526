import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';
import { BookinDetailCardComponent } from './bookin-detail-card/bookin-detail-card.component';
import { tr } from 'date-fns/locale';
declare var require: any;
var moment = require('moment'); // require
@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class BookingsComponent implements OnInit {
  selectedBookingTab = 0;
  selectedBookings: any;
  attendedBookings: any;
  unAttendedBookings: any;
  activeIndex = 0;
  landingBookings: any;
  private geoCoder: any;


  selectedStatus = 'Pending'
  selectedTime = '9:00'

  showCalender = false

  dates: any;

  month = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];

  year = [2021, 2022, 2023, 2024, 2025, 2026]
  selected_day: any;
  selected_day_num: any;
  selected_month: any;
  selected_month_num: any;
  selected_year: any;
  delegates: any;

  now = moment(new Date(Date.now()))

  booking: any;
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;

  showBooking = false
  closeResult = '';
  viewings: any;
  selectedDateIndex: any;



  constructor(private data: DataService, private activatedRoute: ActivatedRoute, private ui: UiService, private modalService: NgbModal, config: NgbModalConfig) {
    config.centered = true
    config.backdrop = 'static'
  
  }

  ngOnInit(): void {
    var that = this
    this.data.getUserForLocationManager('delegates')?.then(users => {
      this.delegates = (users as any).data
      this.getBookings(that);

    })





    // var date_ = moment(this.date)

    this.selected_day = this.now.format('dddd')
    this.selected_day_num = this.now.format('DD')
    this.selected_month = this.now.format('MMMM')
    this.selected_month_num = this.now.format('M')

    this.selected_year = this.now.format('YYYY')

    this.dates = this.getDaysInMonth(parseInt(this.selected_month_num) - 1, parseInt(this.selected_year))

    // console.log(this.dates)

    this.activatedRoute.queryParams.subscribe(r => {
      if (r.booking) {
        this.data.getBooking(r.booking).then(res => {
          console.log(res)
          this.booking = (res as any).data
          this.showBooking = true
          this.open()
        })

      } else {
        // this.showPropDeets = false
      }
    })


  }

  toggleCalender(show:boolean){
    if(show){
      this.getCalenderViewings();
      this.showCalender = show;
    }
    this.showCalender = show
   
   
  }

  private getCalenderViewings() {
    this.data.getCalender(this.selected_month_num, this.selected_year).then(res => {
      this.viewings = (res as any).data;
     
    });
  }

  private getBookings(that: this) {
    this.data.getBookings().then(resb => {

      this.landingBookings = resb as any;


      this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.time === that.selectedTime && el.status === that.selectedStatus;
      });
    });
  }

  open() {
    const modal = this.modalService.open(BookinDetailCardComponent, { size: 'lg' });
    modal.componentInstance.booking = this.booking
    modal.componentInstance.delegates = this.delegates

    modal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getBookings(this)
      },
      (reason) => {
        this.getBookings(this)
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  switchBookingTab(index: any) {
    this.selectedBookingTab = index
    var that = this
    if (index == 0) {
      this.selectedStatus = 'Pending'
      this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.time === that.selectedTime && el.status === that.selectedStatus
      });
    } else {
      this.selectedStatus = 'Viewed'
      this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.time === that.selectedTime && el.status === that.selectedStatus
      });
    }
  }

  toggleIndex(index: number) {
    this.activeIndex = index
    var that = this
    switch (index) {
      case 0:
        that.selectedTime = '9:00'
        this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
          return el.time === '9:00' && el.status === that.selectedStatus
        });
        break;
      case 1:
        that.selectedTime = '1:00'
        this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
          return el.time === '1:00' && el.status === that.selectedStatus
        });
        break;
      case 2:
        that.selectedTime = '3:00'
        this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
          return el.time === '3:00' && el.status === that.selectedStatus
        });
        break;


    }

  }


  getDaysInMonth(month: number, year: number) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }


  onMonthChange(monthvalue: any) {
    this.selected_month_num = parseInt(monthvalue.target.value)+1
    this.getCalenderViewings()
    // this.dates = this.getDaysInMonth(parseInt(monthvalue.target.value), parseInt(this.selected_year))
  }

  onYearChange(yearValue: any) {
    this.selected_year = yearValue.target.value
    this.getCalenderViewings()

    // this.dates = this.getDaysInMonth(parseInt(this.selected_month_num), yearValue.target.value)
    // console.log(this.dates)
  }


  reload() {
    // alert('reload')
    var that = this

    this.data.getBookings().then(resb => {

      this.landingBookings = resb as any



      this.selectedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.time === that.selectedTime && el.status === that.selectedStatus
      });
    })
  }



  markerDragEnd($event: any) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  updateDelegate(d: any) {
    this.data.assignDelegateToBooking(d.slug, this.booking.slug,0,0).then(res => {
      this.ui.showSuccess('Delegate Assigned')
      // this.updatedAgent.emit(true)

    }, rej => {
      this.ui.hideLoader()
      this.ui.showError(rej.errors.message)
    }).catch(e => {

    })

    this.ngOnInit
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 15;
          this.address = '...' + (results[0].formatted_address as string).substring((results[0].formatted_address as string).length - 16);
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }




  selectLocation() {
    // alert('clikc')
    // this.myDrop.close();
    // console.log(this.latitude,this.longitude)
  }

  mapClicked($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }


  showBookingsForDate(event:any){
  this.selected_day = event.date.dayName
  this.selected_day_num = event.date.dayDigit
  this.selected_month = event.month
  this.selected_year = event.year

  this.selectedDateIndex = event.selectedIndex



  // console.log(moment().month(this.selected_month).format("M"))

  // console.log(this.selected_year,this.selected_month_num)


  // fetch bookings for selectino
  this.toggleCalender(false)

  this.getBookings(this)
  }



}
