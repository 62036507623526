<div class="body ps-3">

    <div class="mb-4">
        <div class="flex align-items-center flex-wrap card-container green-container row">

            <div class="col-lg-5 col-md-4 mb-2">
                <button style="font-size: 10px;" pButton type="button" class="text-sm px-3 py-2 blue-bg caption text-button" disabled="true"
                    label="Real Estate"></button>
                <h5 class="caption black-text fs-5 d-inline ms-4">Bookings</h5>
            </div>
            <div class="col-lg-7 col-md-8 text-right row">
                <div class="col">
                    <input placeholder="search" class="afciti-search">
                </div>
                <div class="col-auto">
                    <i class="bi bi-sort-up fs-3 blue-text cursor"></i>
                </div>


            </div>


        </div>

    </div>
   

    <div>
        <div style="background-color: white;" class="col-xl-12 col-md-12">
            <div class="pane  shadow-sm">
                <div class="row me-0">
                    <div class="col m-0 pe-0">
                        <div class="pane-header px-3 pt-3">
                            <ul class="nav nav-pills p-0 m-0 mt-1">
                                <li class="nav-item">
                                    <a (click)="switchBookingTab(0)"
                                        [ngClass]="selectedBookingTab==0?'subtab-active':''"
                                        class="nav-link caption black-text   p-0 me-4 pb-3 pt-1 px-1 cursor">Unattended</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="switchBookingTab(1)"
                                        [ngClass]="selectedBookingTab==1?'subtab-active':''"
                                        class="nav-link caption gray-caption   p-0  pb-3 pt-1 px-1 cursor">Attended</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-auto  m-0 pane-header justify-content-center align-items-center d-flex py-auto">
                        <h5 class="caption fs-2 mx-2 mt-2">{{selected_day_num}}</h5>
                        <div class="mx-2">
                            <span style="font-size: 9px;" class="p-0 caption gray-caption">{{selected_day}}</span>
                            <p style="line-height: 2px;" class="caption mt-1">{{selected_month}} {{selected_year}}</p>
                        </div>
                        <i (click)="toggleCalender(true)" *ngIf="!showCalender"
                            class="fas fa-chevron-down ms-3 cursor me-3"></i>
                        <i (click)="toggleCalender(false)" *ngIf="showCalender"
                            class="fas fa-chevron-up ms-3 cursor me-3"></i>
                    </div>

                    <div class="col-auto  m-0 pane-header ps-0">
                        <ul style="background-color: white;"
                            class="nav nav-pills p-2 shadow-sm rounded  pt-1 mt-2 me-1">
                            <li class="nav-item me-2 cursor">
                                <a class="nav-link caption text-sm" (click)="toggleIndex(0)"
                                    [ngClass]="activeIndex==0?'active':''">9:00AM</a>
                            </li>
                            <li class="nav-item  me-2  cursor">
                                <a class="nav-link caption text-sm" (click)="toggleIndex(1)"
                                    [ngClass]="activeIndex==1?'active':''">1:00PM</a>
                            </li>
                            <li class="nav-item cursor">
                                <a class="nav-link caption text-sm" (click)="toggleIndex(2)"
                                    [ngClass]="activeIndex==2?'active':''">3:00PM</a>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
            <div style="position: relative;">
                <div class="pt-3  d-inline-block" style="background-color: white;height:80vh;overflow-y: scroll;">

                    <ng-container *ngIf="landingBookings">
                        <app-book-card type="button" data-bs-toggle="modal" data-bs-target="modal" (updatedAgent)="reload()" [delegates]="delegates"  [booking]="booking" *ngFor="let booking of selectedBookings"></app-book-card>
                    </ng-container>


                </div>
                <div [@inOutAnimation] *ngIf="showCalender" class="p-3 shadow-lg"
                    style="background-color: #f9f9f9;height:auto;width: 100%;position: absolute;left: 0;top: 0;z-index: 100000;">

                    <div class="row my-2 me-3">
                        <div class="col"></div>
                        <div class="col-auto">
                            <select  (change)="onMonthChange($event)"  class="select caption">
                                <option class="caption" disabled selected>{{selected_month}}</option>
                                <option class="caption" *ngFor="let m of month;let i = index" [value]="i">{{m}}</option>
        
                            </select>
                        </div>
                        <div class="col-auto">
                            <select (change)="onYearChange($event)" class="select caption">
                                <option class="caption" disabled selected>{{selected_year}}</option>
                                <option class="caption" *ngFor="let y of year;let i = index" [value]="y">{{y}}</option>
        
                            </select>
                        </div>
                    </div>
                    <div class="mt-3 p-2" style="overflow:auto;white-space: nowrap;" >
                        <app-date-card (emitSelectedDate)="showBookingsForDate($event)" [date]="d" [year]="viewings.selectedYear" [selectedDateIndex]="selectedDateIndex" [index]="i" [month]="viewings.selectedMonthName" *ngFor="let d of viewings.dates;let i = index"></app-date-card>

                    </div>
                   
                   




                </div>

            </div>

        </div>

       
    </div>


    
</div>




