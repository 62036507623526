<div class="body ps-3">
    <div class=" mb-5">
        <div class="flex align-items-center flex-wrap card-container green-container">
            <!-- <i class="bi bi-geo-alt-fill fs-1 blue-text"></i> -->
            <button style="font-size: 10px;" pButton type="button" class="text-sm px-3 py-2 blue-bg caption text-button" disabled="true"
                label="Home"></button>
            <!-- <i class="bi bi-geo-alt-fill fs-3 px-2 blue-text"></i> -->
            <h4 class="caption blue-text fs-6 d-inline ms-3">Santa Maria</h4>
        </div>

    </div>

    <div style="max-height: 85vh;overflow-y: scroll;" class="row mt-5 pt-2">
        <div class="col-xl-5 col-lg-7 pr-0">
            <span class="fs-1 caption blue-text">Hello,<br>{{user_obj.full_name}},<br></span>
            <span class="fs-1 block gray-caption">welcome.</span>

            <div class="feed-pane mt-1 pt-5">
                <div class="row mr-5 justify-content-center align-items-center">
                    <div class="col">
                        <p class="caption">Feeds</p>
                    </div>
                    <div class="col-auto fs-6 font-light text-right">
                        <p class="col-auto fs-6 font-light text-right">{{date | date:'EEEE, MMMM d, y'}}</p>
                    </div>
                </div>

                <div class="pr-3" style="max-height:550px;overflow-y: scroll">
                    <app-feed-card [feed]="f" *ngFor="let f of feed"></app-feed-card>
                </div>
                


            </div>
        </div>
        <div style="max-height: 87vh;overflow-y: scroll;" 
        class="col-xl-7 col-lg-5 pl-5">
            <div>
                <span class="text-1xl caption">Overview</span>

                <div class="row align-items-center mt-1">
                    <div class="col-1 text-center">
                        <i class="bi bi-house divider-icon"> </i>
                    </div>
                    <div class="col-10 pb-4">
                        <p-divider></p-divider>
                    </div>
                </div>

                <div class="flex mt-1">
                    <app-stats-card  [name]="'Agents'" [value]="assetsMetrics.agents"></app-stats-card>
                    <app-stats-card [name]="'Landlords'" [value]="assetsMetrics.landlords"></app-stats-card>
                    <app-stats-card [name]="'Properties'" [value]="assetsMetrics.properties"></app-stats-card>
                    <app-stats-card [name]="'Delegates'" [value]="assetsMetrics.delegates"></app-stats-card>
                    <app-stats-card [name]="'Bookings'" [value]="assetsMetrics.bookings"></app-stats-card>
                    <app-stats-card   [name]="'Tenants'" [value]="0"></app-stats-card>
                </div>

            </div>

            <!-- <div class="mt-5">

                <div class="row align-items-center mt-1">
                    <div class="col-1 text-center">
                        <i class="bi bi-person divider-icon"> </i>
                    </div>
                    <div class="col-10 pb-4">
                        <p-divider></p-divider>
                    </div>
                </div>

                <div class="flex mt-1">
                    <app-stats-card [data]="m" *ngFor="let m of usageMetrics"></app-stats-card>
                </div>
            </div> -->


        </div>
    </div>
</div>