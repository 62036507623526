<div class="feed-card shadow-sm my-2">
    
    <div class="row justify-content-center align-items-center g-5">
        <div  class="col-2 text-center">
            <img  style="cursor: pointer;" src="https://github.com/mdo.png" alt="hugenerd" width="40" height="40"
            class="rounded-circle"> 
        </div>
        <div class="col-10">
            <p-tag styleClass="krona" [style]="{'color':'white','font-size':'6px','background-color':'#3B59EE','padding-left':'10px','padding-right':'10px','padding-top':'3px','padding-bottom':'3px'}" value="Delegate" [rounded]="true"></p-tag>
            <p style="font-size: 10px;" class=" m-0 caption">Ntim Afriyie is asking for transfer</p>
            <span class="m-0 cursor" style="font-style: italic;font-size: 7px;">Click to take action</span>
        </div>
    </div>
    
</div>