import { environment } from './../../environments/environment';
import { UiService } from './../services/ui.service';
import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginForm = new FormGroup({
    email: new FormControl(environment.unname, [Validators.email, Validators.required]),
    password: new FormControl(environment.pass, [Validators.required])
  })

  constructor(private router: Router, private dataService: DataService, private ui: UiService) {

  }

  ngOnInit(): void {
  }


  login() {
    if (this.loginForm.valid) {
      this.dataService.login(
        this.loginForm.value
      ).then(res => {
        if ((res as any).data.account_type === "LocationManager") {
          localStorage.setItem('user_id',(res as any).data.slug)
          localStorage.setItem('access_token', (res as any).token)
          location.reload()
        } else {
          this.ui.hideLoader()
          this.ui.showError("Invalid account type")
        }
        // console.log((res as any).data.account_type)


      }, rej => {
        this.ui.hideLoader()
        this.ui.showError(rej.message)
      })
    } else {
      this.ui.showError('Fill all form fields')
    }


  }
}
