import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {
  properties: any;
  page: any;

  propStatus = ['Lot','Available','Rented','Leased','Bought']
  availability = ""

  constructor(
    private data: DataService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private ui: UiService
  ) {
    this.data.getProperties(1,this.availability).then(resa => {
      this.properties = (resa as any)
    })
  }
  activePropertyStateIndex = 0
  // currentPage = 1

  ngOnInit(): void {
    // var p;
    var sub = this.activeRoute.queryParams.subscribe(res => {
      if (res.p) {
        this.data.getProperties(res.p,this.availability).then(resa => {
          // window.location.
          // p = res.p
         

          this.properties = (resa as any)

        })
      }
    })

    // sub.unsubscribe()
    // this.page = p


  }


  togglePropertyStateIndex(index: any) {
    this.activePropertyStateIndex = index

    this.availability = this.propStatus[index]

    this.ngOnInit()

    
  }


  pageChange(event: any) {
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: {
        p: event
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });

    this.ngOnInit()
    // this.data.getProperties(event).then(resa => { 
    //   // window.location.
    //   this.properties = (resa as any)



    // })
  }

  navToProp(id: any) {
    this.router.navigate(['' + id], { relativeTo: this.activeRoute })
  }

}
