<div class="position-relative d-inline-block">
    <div style="left: 0;top: 0;width: 100%;height: 100%;" (click)="emit(property.slug)" class="card d-inline-block mx-1 my-2 shadow-sm cursor">
        <img height="250px!important" width="250px" style="object-fit: cover;"  [src]="property.image">
        <div  class="p-2 mt-4">
            <p class="property-type blue-text">{{property.let_off_type}}</p>
            <p class="property-title black-text">{{property.title}}</p>
            <!-- <p class="property-title black-text">3 Bedroom at Abgoga, Haatso</p> -->
    
            <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-50 my-2">
            <p class="property-cost black-text mb-1">{{property.price | currency:'GHC '}}</p>
    
        </div>
    </div>
    <button style="left: 10px;top: 238px;font-weight: 500;font-size: 8px;" class="position-absolute badge rounded-pill blue-bg py-1 px-2 m-1  mt-2">{{property.availability}}</button>
</div>


