import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.css']
})
export class StatsCardComponent implements OnInit {
  @Input('name') name: any = {}
  @Input('value') value: any = {}
  
  constructor() { }

  ngOnInit(): void {
  }

}
