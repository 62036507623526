import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';
import { MapComponent } from './map/map.component';

@Component({
  selector: 'app-bookin-detail-card',
  templateUrl: './bookin-detail-card.component.html',
  styleUrls: ['./bookin-detail-card.component.css']
})
export class BookinDetailCardComponent implements OnInit {
  booking: any;
  delegates: any;


  delegateSelected: boolean = false;
  locationSelected: boolean = false;


  @ViewChild('myDrop', { static: true }) myDrop: any;

  title: string = '';
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  private geoCoder: any;

  @ViewChild('search')
  public searchElementRef: ElementRef | undefined;
  addressObj: any;
  selectedDelegate: any;
  delegateChanged: boolean = false
  locationChanged: boolean = false


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private modalService: NgbModal, private data: DataService, private ui: UiService, private location: Location, config: NgbModalConfig) {
    // config.backdrop = 'static'
    config.centered = true
    this.data.getUserForLocationManager('delegates')?.then(users => {
      this.delegates = (users as any).data
    })
  }

  ngOnInit(): void {

    // console.log(this.booking)
    this.delegateChanged = this.booking.delegate
    this.selectedDelegate = this.booking.delegate

  }

  view() {
    this.router.navigateByUrl('/u/properties/' + this.booking.property_data.slug)
    this.modalService.dismissAll()
  }


  close() {
    this.location.back()
    this.modalService.dismissAll()
  }


  selectLocation() {
    // alert('clikc')
    this.myDrop.close();
    console.log(this.latitude, this.longitude)
  }

  mapClicked($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }


  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 15;
          this.address = '...' + (results[0].formatted_address as string).substring((results[0].formatted_address as string).length - 16);
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  markerDragEnd($event: any) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  updateDelegate(d: any) {
    this.delegateChanged = true
    this.selectedDelegate = d

  }



  showMap() {
    const modal = this.modalService.open(MapComponent, { backdrop: 'static', size: 'xl' });
    modal.componentInstance.booking = this.booking

    modal.result.then(
      (result) => {
        this.addressObj = result
        this.address = result.fullAddress
        this.locationChanged = true
        //  console.log(result)
      },
      (reason) => {

      },
    );
  }

  saveChanges() {
    this.data.assignDelegateToBooking(this.selectedDelegate.slug, this.booking.slug,this.addressObj.lng,this.addressObj.lat).then(res => {
      this.data.getBooking(this.booking.slug).then(res => {
        this.booking = (res as any).data

        // this.data.updateMeetupLocation(this.booking.slug, this.addressObj.lat, this.addressObj.lng).then(res => {
          this.ui.showSuccess("Delegate Assigned")
          this.modalService.dismissAll()
        //   this.ngOnInit()

        // }).catch(rej => {
        //   this.ui.hideLoader()
        //   this.ui.showError((rej as any).message)

        // })

      })
    }, rej => {
      this.ui.hideLoader()
      this.ui.showError(rej.errors.message)
    }).catch(e => {
    })

  }

}
