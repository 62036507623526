<div class="body ps-3">

    <div class=" mb-4">
        <div class="flex align-items-center flex-wrap card-container green-container row">

            <div class="col-lg-5 col-md-4 mb-2">
                <button style="font-size: 10px;" pButton type="button"
                    class="text-sm px-3 py-2 blue-bg caption text-button" disabled="true" label="Real Estate"></button>
                <h5 class="caption black-text fs-5 d-inline ms-4">Property Lot</h5>
            </div>
            <div class="col-lg-7 col-md-8 text-right row">
                <div class="col">
                    <input placeholder="search" class="afciti-search">
                </div>
                <div class="col-auto">
                    <i class="bi bi-sort-up fs-3 blue-text cursor"></i>
                </div>


            </div>


        </div>

    </div>


    <div>
        <div style="background-color: white;" class="col-xl-12 col-md-12">
            <div class="pane  shadow-sm">
                <div class="row me-0">
                    <div class="col m-0 pe-0">
                        <div class="pane-header px-3 pt-3">
                            <ul class="nav nav-pills p-0 m-0 mt-1">
                                <li class="nav-item">
                                    <a class="nav-link caption black-text   p-0 me-4 pb-3 pt-1 px-2 cursor">All</a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link caption gray-caption   p-0  me-4  pb-3 pt-1 px-2 cursor">Popular</a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link caption gray-caption   p-0 me-4  pb-3 pt-1 px-2 cursor">Featured</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link caption gray-caption   p-0 me-4  pb-3 pt-1 px-2 cursor">Deals</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link caption gray-caption   p-0 me-4   pb-3 pt-1 px-2 cursor">PLAs</a>
                                </li>


                            </ul>
                        </div>
                    </div>
                    
                    <div class="col-auto  m-0 pane-header ps-0">
                        <ul style="background-color: white;"
                            class="nav nav-pills p-2 shadow-sm rounded  pt-1 mt-2 me-1">
                            <li *ngFor="let p of propStatus;let i = index" class="nav-item me-2 cursor">
                                <a class="nav-link caption text-sm" (click)="togglePropertyStateIndex(i)"
                                    [ngClass]="activePropertyStateIndex==i?'active':''">{{p}}</a>
                            </li>
                            <!-- <li class="nav-item  me-2  cursor">
                                <a class="nav-link caption text-sm" (click)="togglePropertyStateIndex(1)"
                                    [ngClass]="activePropertyStateIndex==1?'active':''">Hired / Bought</a>
                            </li>
                            <li class="nav-item cursor">
                                <a class="nav-link caption text-sm" (click)="togglePropertyStateIndex(2)"
                                    [ngClass]="activePropertyStateIndex==2?'active':''">Removed</a>
                            </li> -->
                        </ul>
                    </div>
                </div>


            </div>
            <div>
                <div class="pt-3  d-inline-block" style="background-color: white;height:75vh;overflow-y: scroll;">

                    <ng-container *ngIf="properties">
                        <app-property-card (propertyIdEmitter)="navToProp($event)" [property]="property"
                            *ngFor="let property of properties.data"></app-property-card>
                    </ng-container>

                </div>
                <div class="row">
                    <div class="col"></div>
                    <div class="col-auto">
                        <ngb-pagination  class="pagination-rounded" [collectionSize]="properties?properties.total:0"
                            (pageChange)="pageChange($event)" ></ngb-pagination>
                    </div>
                    <div class="col"></div>
                </div>


            </div>

        </div>


    </div>





</div>