import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import {BadgeModule} from 'primeng/badge';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {EditorModule} from 'primeng/editor';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {MessageModule} from 'primeng/message';
import {PasswordModule} from 'primeng/password';
import {CheckboxModule} from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {RippleModule} from 'primeng/ripple';
import { TagModule } from 'primeng/tag';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {TooltipModule} from 'primeng/tooltip';
import {PaginatorModule} from 'primeng/paginator';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {FieldsetModule} from 'primeng/fieldset';
import {TimelineModule} from 'primeng/timeline';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CardModule} from 'primeng/card';
import {SidebarModule} from 'primeng/sidebar';
import {BlockUIModule} from 'primeng/blockui';
import {AccordionModule} from 'primeng/accordion';
import {SkeletonModule} from 'primeng/skeleton';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],exports: [
    ReactiveFormsModule,
    FormsModule,    
    DividerModule,
    InputTextModule,
    BadgeModule,
    TableModule,
    CalendarModule,
    InputSwitchModule,
    RippleModule,
		SliderModule,
		DialogModule,
    TimelineModule,
    CardModule,
    SkeletonModule,
		MultiSelectModule,
		ContextMenuModule,
    FieldsetModule,
		DropdownModule,
    ScrollTopModule,
    EditorModule,
    PasswordModule,
		ButtonModule,
    TooltipModule,
		ToastModule,
    ProgressBarModule,
    MessageModule,
    FileUploadModule,
    FullCalendarModule,
    MultiSelectModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    OverlayPanelModule,
    TagModule,
    AvatarModule,
    ConfirmPopupModule,
    AvatarGroupModule,
    PaginatorModule,
    FileUploadModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    SidebarModule,
    MultiSelectModule,
    BlockUIModule,
    AccordionModule,
     ],
})
export class SharedModule { }
