export enum urls {
    login = '/auth/login',
    properties = '/property/lm-properties/',
    property = '/property/',
    bookings = '/appointment/get-appointments-for-location-manager/',
    delegates = '/delegate/get-delegates-for-location-manager/',
    landlords = '/landlord/get-landlords-for-location-manager/',
    assign_delegate = '/appointment/assign-delegate',
    user = '/user/',
    set_publish_status = '/property/set-publish-status/',
    get_amenities = '/property-amenities',
    get_booking = '/appointment/get-appointment/',
    update_amenities = '/property/update-amenities',
    update_pla = '/property/pla/update',
    update_meetup_location = "/key-facilitation/update-meetup-location",
    stats = '/app/get-statistic-for-location-manager/',
    calendar = '/appointment/get-calender?'
}