import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-user-card',
  templateUrl: './team-user-card.component.html',
  styleUrls: ['./team-user-card.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.5s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.05s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class TeamUserCardComponent implements OnInit {

  @Input('user') user:any;
  toggle = false
  constructor() { }

  ngOnInit(): void {
  }

}
