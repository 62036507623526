<div class="body ps-3">

    <div class=" mb-4">
        <div class="flex align-items-center flex-wrap card-container green-container row">

            <div class="col-lg-5 col-md-4 mb-2">
                <button style="font-size: 10px;" pButton type="button"
                    class="text-sm px-3 py-2 blue-bg caption text-button" disabled="true" label="Real Estate"></button>
                <h5 class="caption black-text fs-5 d-inline ms-4">Team</h5>
            </div>
            <div class="col-lg-7 col-md-8 text-right row">
                <div class="col">
                    <input placeholder="search" class="afciti-search">
                </div>
                <div class="col-auto">
                    <i class="bi bi-sort-up fs-3 blue-text cursor"></i>
                </div>


            </div>


        </div>

    </div>


    <div class="row m-0 p-0">
        <div class="col">
            <div style="background-color: white;" class="col-xl-12 m-0 p-0 col-md-12">
                <div class="pane  shadow-sm">
                    <div class="row me-0 justify-content-center align-items-center">
                        <div class="col m-0 pe-0">
                            <div class="pane-header px-3 pt-3">
                                <ul class="nav nav-pills p-0 m-0 mt-1">
                                    <li class="nav-item d-inline">
                                        <a (click)="selectUser('delegates')"
                                            [ngClass]="selectedUserTab=='delegates'?'subtab-active black-text':'gray-caption'"
                                            class="nav-link caption p-0 me-3 pb-3 pt-1 px-1 cursor">Delegates</a>
                                    </li>
                                    <li class="nav-item d-inline">
                                        <a [ngClass]="selectedUserTab=='agents'?'subtab-active black-text':'gray-caption'"
                                            (click)="selectUser('agents')"
                                            class="nav-link caption    p-0 me-3 pb-3 pt-1 px-1 cursor">Agents</a>
                                    </li>
                                    <li class="nav-item d-inline">
                                        <a [ngClass]="selectedUserTab=='landlords'?'subtab-active black-text':'gray-caption'"
                                            (click)="selectUser('landlords')"
                                            class="nav-link caption    p-0 me-3 pb-3 pt-1 px-1 cursor">Landlords</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div>
                <div class="pt-3  d-inline-block"
                    style="background-color: white;height:80vh;overflow-y: scroll;width: 100%;">
                    <app-team-user-card [user]="user" *ngFor="let user of selectedUsers"></app-team-user-card>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div style="background-color: white;" class="col-xl-12 col-md-12">
                <div class="pane  shadow-sm">
                    <div class="row me-0 justify-content-center align-items-center">
                        <div class="col m-0 pe-0">
                            <div class="pane-header px-3 pt-3 pb-1">
                                <button style="font-size: 15px;background-color: transparent;" type="button" class="position-relative caption black-text p-1">
                                   
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill blue-bg">
                                      3
                                      <span class="visually-hidden">unread messages</span>
                                      
                                    </span>
                                    Notifications
                                  </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div>
                <div class="pt-3  d-inline-block"
                     style="background-color: white;height:80vh;overflow-y: scroll;width: 100%;">
                    <app-notif-card></app-notif-card>
                    <app-notif-card></app-notif-card>
                    <app-notif-card></app-notif-card>
                    <app-notif-card></app-notif-card>
                    <app-notif-card></app-notif-card>
                </div>
            </div>
        </div>
        
        
    </div>
</div>