import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { ThrowStmt } from '@angular/compiler';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  title: string = 'AGM project';
  booking: any;
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  fullAddress: any = ''
  private geoCoder: any;

  markers: marker[] = []




  @ViewChild('search')
  public searchElementRef: ElementRef | undefined;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private data: DataService, private ui: UiService) { }

  ngOnInit(): void {

    this.longitude = 5.0
    this.latitude = 0.2


    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef!.nativeElement);
      // alert(autocomplete)
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
          this.getAddress(this.latitude, this.longitude);

        });
      });
    });






  }


  close() {
    this.activeModal.close()

  }

  selectLocation() {
    this.activeModal.close({ lat: this.markers[1].lat, lng:this.markers[1].lng,address:this.address,fullAddress:this.fullAddress })

    // this.data.updateMeetupLocation(this.booking.slug,this.markers[1].lat,this.markers[1].lng).then(res=>{
    //   this.ui.showSuccess((res as any).message)

    // }).catch(rej=>{
    //   this.ui.hideLoader()
    //   this.ui.showError((rej as any).message)

    // })


  }

  mapClicked($event: any) {
    // this.markers = []
    this.markers[1] = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
      label: '',
      icon: ''
    }
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    this.getAddress(this.markers[1].lat, this.markers[1].lng);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {

        if (results[0]) {
          this.zoom = 15;
          this.fullAddress = results[0].formatted_address
          this.address = '...' + (results[0].formatted_address as string).substring((results[0].formatted_address as string).length - 16);
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  markerDragEnd($event: any) {
    // console.log($event);
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    // this.getAddress(this.latitude, this.longitude);
  }


  private setCurrentLocation() {
    if (this.booking.latitude && this.booking.longitude) {
      this.latitude = parseFloat(this.booking.latitude);
      this.longitude = parseFloat(this.booking.longitude);
      this.markers.push({
        lat: this.latitude,
        lng: this.longitude,
        draggable: false,
        label: '',
        icon: 'https://maps.gstatic.com/mapfiles/ms2/micons/homegardenbusiness.png'
      });
      this.zoom = 15;
    } else {
      this.latitude = 5.6394451,
        this.longitude = -0.2510983
      this.zoom = 15;
      this.markers.push({
        lat: this.latitude,
        lng: this.longitude,
        draggable: false,
        label: '',
        icon: 'https://maps.gstatic.com/mapfiles/ms2/micons/homegardenbusiness.png'
      });
      this.markers.push({
        lat: 0,
        lng: 0,
        draggable: false,
        label: '',
        icon: 'https://maps.gstatic.com/mapfiles/ms2/micons/homegardenbusiness.png'
      });
    }







    // if ('geolocation' in navigator) {
    //   this.latitude = parseFloat(this.booking.latitude);
    //     this.longitude = parseFloat(this.booking.longitude);
    //     this.zoom = 15;
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     // this.latitude = position.coords.latitude;
    //     // this.longitude = position.coords.longitude;

    //     // this.getAddress(this.latitude, this.longitude);

    //   });
    // }
  }

}


interface marker {
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
  icon: string
}