<!-- <div class="row">

    
    <div class="col-1 sidebar  justify-content-center align-items-center text-center">
        <button pButton pRipple type="button" icon="pi pi-map-marker" class="p-button-rounded shadow" routerLink="dashboard" routerLinkActive="active"> </button>                           
        <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded" routerLink="home" routerLinkActive="active"> </button>    
        <button pButton pRipple type="button" icon="pi pi-comment" class="p-button-rounded" routerLink="team" routerLinkActive="active"> </button>    
        <button pButton pRipple type="button" icon="pi pi-key" class="p-button-rounded"> </button>    
    </div>
    <div class="col-11 mainbody">
        <router-outlet></router-outlet>
    </div>
</div> -->

<div class="">
    <div class="row flex-nowrap no-gutters">
        <div class="d-none d-lg-block col-auto mx-auto p-0 m-0 px-3">
            <div class="d-flex flex-column align-items-center  px-auto pt-5 text-white min-vh-100">
                <!-- <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span class="fs-5 d-none d-sm-inline">Menu</span>
                </a> -->
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li class="nav-item">
                        <a  routerLink="dashboard" routerLinkActive="active" class="nav-link px-0 align-middle">
                            <i class="fs-3 bi-geo-alt-fill nav-i" ></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a  routerLink="home" [ngClass]="{'active': bookingsActive.isActive}" [ngClass]="{'active': team.isActive}" [ngClass]="{'active': properties.isActive}"  routerLinkActive="active" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                            <i class="fs-3 bi-house nav-i"></i>
                        </a>

                    </li>
                    <a routerLink="bookings" routerLinkActive #bookingsActive="routerLinkActive" style="display: none"></a>
                    <a routerLink="team" routerLinkActive #team="routerLinkActive" style="display: none"></a>
                    <a routerLink="properties" routerLinkActive #properties="routerLinkActive" style="display: none"></a>
                    <!-- <li class="nav-item">
                        <a routerLink="x" routerLinkActive="active"class="nav-link px-0 align-middle">
                            <i class="fs-3 bi-chat-right nav-i"></i>
                        </a>
                    </li> -->
                    <li class="nav-item">
                        <a routerLink="key-facilitation" [queryParams]="{p: 1}" routerLinkActive="active" data-bs-toggle="collapse" class="nav-link px-0 align-middle ">
                            <i class="fs-3 pt-1 fas fa-key nav-i"></i></a>

                    </li>
                    
                </ul>
                <hr>
                <div class="dropdown pb-4">
                    
                        <img (click)="logout()" style="cursor: pointer;" src="https://github.com/mdo.png" alt="hugenerd" width="50" height="50"
                            class="rounded-circle">
                        
                </div>
            </div>
        </div>
        <div class="col m-0 p-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>