<div  *ngIf="booking.property_data && booking.assigned==false" style="overflow: hidden;width: 350px;"
    class="booking shadow-sm  mb-0 p-0  mx-3 rounded  d-inline-block">

    <div *ngIf="booking.property_data" style="overflow: hidden;height: 100px;background-color: white;z-index: 100000;"
        class="booking shadow-sm  mb-0 p-0 row">
        <div (click)="emit(booking.slug)" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#staticBackdrop'+booking._id"
            class="col-4 p-0 m-0">
            <img  style="object-fit: cover;width: 100%;border-radius: 10px;" height="100%!important"
                [src]="booking.property_data.image">
        </div>
        <div class="col-8 p-2 justify-content-center align-items-center my-auto ps-3">
            <p (click)="emit(booking.slug)" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#staticBackdrop'+booking._id"
                class="property-title black-text caption m-0 ">{{booking.property_data.title}}</p>

            <div (click)="emit(booking.slug)" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#staticBackdrop'+booking._id"
                class="row  justify-content-center align-items-center g-0 p-1">
                <div class="col-auto m-0 pe-2">
                    <i class="far fa-calendar-check blue-text"></i>
                </div>
                <div class="col m-0 p-0">
                    <p class="m-0 p-0 small-text">{{booking.date | date: 'dd MMM, YYYY'}}</p>
                </div>
            </div>
            <div (click)="emit(booking.slug)" type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#staticBackdrop'+booking._id"
                class="row  justify-content-center align-items-center no-gutters pt-0 p-1">
                <div class="col-auto m-0 pe-2">
                    <i class="far fa-clock blue-text"></i>
                </div>
                <div class="col m-0 p-0">
                    <p class="m-0 p-0 small-text">{{booking.time}}</p>
                </div>
            </div>
         


            <!-- <div container="body" ngbDropdown class="mt-2 row g-0 p-1 me-2  justify-content-center align-items-center"
                style="height: auto;background-color: #f9f9f9;border-radius: 30px;">
                <div id="dropdownBasic1" ngbDropdownToggle class="col-auto"
                    style="height: 22px;width: 22px;border-radius: 50%;background-color: #3B59EE;"></div>
                <div id="dropdownBasic1" ngbDropdownToggle
                    class="col px-2 justify-content-center align-items-center cursor">
                    <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">AGENT</p>
                    <p *ngIf="!booking.delegate" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">Choose
                    </p>
                    <p *ngIf="booking.delegate" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                        {{booking.delegate_data.first_name}} {{booking.delegate_data.last_name}}</p>
                </div>
                <div id="dropdownBasic1" ngbDropdownToggle class="col-auto">
                    <i class="bi bi-caret-down-fill  px-3  blue-text"></i>
                </div>
                <div *ngIf="delegates" class="pt-3 mt-1 shadow-lg" style="background-color: #f9f9f9;border: none;"
                    ngbDropdownMenu aria-labelledby="dropdownBasic1">


                    <div *ngFor="let d of delegates" (click)="updateDelegate(d)" ngbDropdownItem class="my-2 cursor">
                        <div class="row justify-content-center align-items-center g-3">
                            <div class="col-auto">
                                <img style="cursor: pointer;background:url('https://image-placeholder.com/images/actual-size/57x57.png');background-color: transparent;"
                                    [src]="d.photo" alt="" width="30" height="30" class="rounded-circle">
                            </div>
                            <div class="col">
                                <p style="font-size: 9px;" class="property-title black-text caption m-0">
                                    {{d.first_name}} {{d.last_name}}</p>
                            </div>
                            <div class="col-auto">
                                <div class="dot"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="p-0 row  shadow-sm px-1"
        style="height: 50px;background-color: #e6edff;margin-top: -5px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
        <div class="row justify-content-center align-items-center">
            <div class="col">
                <span style="font-size: 8px;" class="blue-text text-sm caption"><i
                        class="bi bi-caret-down-fill cursor px-3  blue-text"></i>{{booking.prospects.length}} Person
                    Attending</span>
            </div>
            <div class="col-auto row">
                <div class="col">
                    <i class="bi bi-geo-alt-fill cursor  blue-text"></i>
                </div>
                <div class="col">
                    <i class="bi bi-person-fill cursor   blue-text"></i>
                </div>
                <div class="col">
                    <i class="bi bi-telephone-fill cursor   blue-text"></i>
                </div>
            </div>
        </div>
    </div>
</div>