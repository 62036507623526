declare var require: any
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
var moment = require('moment'); // require

@Component({
  selector: 'app-date-card',
  templateUrl: './date-card.component.html',
  styleUrls: ['./date-card.component.css']
})
export class DateCardComponent implements OnInit {
  @Input('date') date: any;
  @Input('year') year: any;
  @Input('month') month: any;
  @Input('index') index: any;
  @Input('selectedDateIndex') selectedDateIndex: any;


  @Output() emitSelectedDate = new EventEmitter();

  isActive: any;

  day: any;
  day_num: string = ""
  // month: any;
  // year: any;
  constructor() { }

  ngOnInit(): void {
    // var date_ = moment(this.date)

    // this.day = date_.format('dddd')
    // this.day_num = date_.format('DD')
    // this.month = date_.format('MMMM')
    // this.year = date_.format('yyyy')


    var todayDD = String(moment(new Date(Date.now())).format('DD'))
    var todayMMMM = moment(new Date(Date.now())).format('MMMM')
    var todayYYYY = moment(new Date(Date.now())).format('YYYY')

    var viewingDD = this.date.dayDigit


  


    if (!this.selectedDateIndex) {
      this.isActive = (todayDD == viewingDD && todayMMMM == this.month && todayYYYY == this.year)
    } else {
      this.isActive = (this.selectedDateIndex == this.index)
    }


    // this.isActive = (moment(this.date).format('DD/MM/y') === moment(new Date(Date.now())).format('DD/MM/y'))

  }


  selectDate() {
    this.emitSelectedDate.emit({ date: this.date, month: this.month, year: this.year, selectedIndex: this.index })
  }




}
