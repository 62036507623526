<div class="feed-card">
    
    <div class="row justify-content-center align-items-center">
        <div  class="col-2 text-center">
            <p-avatar [icon]="feed.type=='REAL ESTATE'?'bi bi-house':'bi bi-person'" styleClass=" flex align-items-center justify-content-center" size="large"
            [style]="{'background-color':'white', 'color': '#3B59EE','border-color':'#3B59EE','border-width':'2px','border-style':'solid'}" shape="circle"></p-avatar>
        </div>
        <div class="col-10">
            <p-tag styleClass="krona" [style]="{'color':'white','font-size':'8px','background-color':'#3B59EE','padding-left':'10px','padding-right':'10px','padding-top':'5px','padding-bottom':'5px'}" value="{{feed.type | titlecase}}" [rounded]="true"></p-tag>
            <p style="font-size: 13px;" class="m-1">{{feed.message}}</p>
            <span class="m-1 cursor" style="font-style: italic;font-size: 10px;">See Details</span>
        </div>
    </div>
    
</div>