import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MockApiService {

  assets = [
    {
      name: "PROPERTIES",
      value: 122
    },
    {
      name: "AGENTS",
      value: 14
    },
    {
      name: "LANDLORDS",
      value: 3
    },
    {
      name: "BOOKINGS",
      value: 144
    },
    {
      name: "DELEGATES",
      value: 19
    },
  ]

  usage = [
    {
      name: "ARTISANS",
      value: 100
    },
    {
      name: "REQUESTS",
      value: 3014
    },
    {
      name: "SUCCESSES",
      value: 2093
    },
  ]
  

  feed = [
    {
      type:'REAL ESTATE',
      message:'Akua Asante has moved in into a property in your area'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'REAL ESTATE',
      message:'Akua Asante has moved in into a property in your area'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'REAL ESTATE',
      message:'Akua Asante has moved in into a property in your area'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'REAL ESTATE',
      message:'Akua Asante has moved in into a property in your area'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    },
    {
      type:'SERVICES',
      message:'Kofi Lamar, a carpenter has been clocked in by Akua Asante at GC-0293-01 at 1:05pm, today'
    }
    
  ]


  constructor() { }



  getAssetStats() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.assets)
      })
    })

  }

  getUsageStats() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.usage)
      })
    })
  }


  getFeed(){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.feed)
      })
    })
  }

}


