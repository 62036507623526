import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryAnimation, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';
import { BookinDetailCardComponent } from '../../bookings/bookin-detail-card/bookin-detail-card.component';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.css']
})
export class PropertyDetailsComponent implements OnInit {
  property: any
  galleryImages: any[] = [];
  updateAmenities = false
  updateTriggered = false



  dropdownList: any;
  selectedItems: any;
  dropdownSettings: IDropdownSettings = {};

  galleryOptions: NgxGalleryOptions[] = [

    {
      width: '600px',
      height: '600px',
      thumbnailsColumns: 4,
      imageAnimation: NgxGalleryAnimation.Slide,
      thumbnailClasses: ['no-border'],
      thumbnailsRows: 1


    },
    // max-width 800
    {
      breakpoint: 800,
      width: '100%',
      height: '600px',
      imagePercent: 80,
      thumbnailsPercent: 20,
      thumbnailsMargin: 20,
      thumbnailMargin: 20,
      thumbnailClasses: ['no-border']
    },
    // max-width 400
    {
      breakpoint: 400,
      preview: false
    }
  ];
  booking: any;
  delegates: any;

  constructor(private activatedRoute: ActivatedRoute, private data: DataService, private location: Location, private ui: UiService,private router:Router,private modalServce:NgbModal) {
    activatedRoute.params.subscribe(val => {
      this.data.getProperty(val.id).then(res => {
        this.property = res
        this.makeImages(res)

        this.data.getAmenities().then(res => {
          this.dropdownList = (res as any).data

          this.selectedItems = this.property.amenities_data
        })
      })
    })

    this.data.getUserForLocationManager('delegates')?.then(users => {
      this.delegates = (users as any).data
    })
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(val => {
      this.data.getProperty(val.id).then(res => {
        this.property = res
        this.makeImages(res)

        this.data.getAmenities().then(res => {
          this.dropdownList = (res as any).data

          this.selectedItems = this.property.amenities_data
        })
      })
    })


    this.activatedRoute.queryParams.subscribe(r => {
      if (r.booking) {
        this.data.getBooking(r.booking).then(res => {
          
          this.booking = (res as any).data
          this.open()
          
          // this.selectedProp = res
          // this.galleryImages = []
          // this.makeImages(this.selectedProp)
          // this.showPropDeets = true
        })

      } else {
        // this.showPropDeets = false
      }
    })

    this.updateAmenities = false

    this.data.getAmenities().then(res => {
      this.dropdownList = (res as any).data

      this.selectedItems = this.property.amenities_data
    })

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'slug',
      textField: 'name',
      selectAllText: 'Select all',
      unSelectAllText: 'Deselect all',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
  }


  back() {
    this.location.back()
  }

  makeImages(obj: any) {
    this.galleryImages = []
    // console.log(obj.kitchen_images)
    this.galleryImages.push({
      small: obj.image as any,
      medium: obj.image as any,
      big: obj.image as any
    })
    try {
      Object.values(obj.kitchen_images).forEach(value => {
        if ((value as string).startsWith('https')) {
          this.galleryImages.push(
            {
              small: value as any,
              medium: value as any,
              big: value as any
            }
          )
        }
      })
    }

    catch (error) {
      console.log(error)
    }

    try {
      Object.values(obj.bedroom_images).forEach(value => {
        if ((value as string).startsWith('https')) {
          this.galleryImages.push(
            {
              small: value as any,
              medium: value as any,
              big: value as any
            }
          )
        }
      })
    } catch (error) {
      console.log(error)
    }


    try {
      Object.values(obj.other_images).forEach(value => {
        if ((value as string).startsWith('https')) {
          this.galleryImages.push(
            {
              small: value as any,
              medium: value as any,
              big: value as any
            }
          )
        }
      })
    } catch (error) {
      console.log(error)
    }

    // console.log(this.galleryImages)

  }


  publish(status: boolean) {
    if (status) {
      this.data.setPublishStatus(this.property.slug, 'Published').then(res => {
        this.ui.showSuccess((res as any).message)
      }).catch(rej => {
        this.ui.showError((rej as any).message)
      })
    } else {
      this.data.setPublishStatus(this.property.slug, 'Unpublished').then(res => {
        this.ui.showSuccess((res as any).message)
      }).catch(rej => {
        this.ui.hideLoader()
        this.ui.showError((rej as any).message)
      })
    }
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }


  saveAmenities() {
    var amenties: string[] = []
    this.selectedItems.forEach((element: { slug: any; }) => {
      amenties.push(element.slug)
    });
    this.data.updateAmenties(this.property.slug, amenties).then(res => {
      this.ui.showSuccess((res as any).message)
      window.location.reload()
    }).catch(rej => {
      this.ui.hideLoader()
      this.ui.showError((rej as any).message)
      this.ngOnInit()

    })
  }


  plaFocused(){
    this.updateTriggered = true
  }


  plaBlurred(){
    this.updateTriggered = false

  }

  updatePLA(){
    this.data.updatePLA(this.property.slug, this.property.pla).then(res=>{
      this.ui.showSuccess((res as any).message)
      this.ngOnInit()
    }).catch(rej => {
      this.ui.hideLoader()
      this.ui.showError((rej as any).message)
      this.ngOnInit()

    })
  
    this.updateTriggered = false
  }


  showBooking(slug:string){
    this.router.navigate([],{queryParams:{booking:slug}})
  }


  open() {
    const modal = this.modalServce.open(BookinDetailCardComponent, {backdrop:'static',centered:true,size: 'lg' });
    modal.componentInstance.booking = this.booking
    modal.componentInstance.delegates = this.delegates

    modal.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );


  }

}
