import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-key-facilitation',
  templateUrl: './key-facilitation.component.html',
  styleUrls: ['./key-facilitation.component.css']
})
export class KeyFacilitationComponent implements OnInit {
  propStatus = ['Rented','Bought']
  activePropertyStateIndex:any = 0
  availability = "Rented";
  properties:any;

  constructor(private router:Router,private activeRoute:ActivatedRoute,private data:DataService) {
    this.data.getProperties(1,this.availability).then(resa => {
      this.properties = (resa as any)
    })
   }

  ngOnInit(): void {
    var sub = this.activeRoute.queryParams.subscribe(res => {
      if (res.p) {
        this.data.getProperties(res.p,this.availability).then(resa => {
          this.properties = (resa as any)
        })
      }
    })
  }


  togglePropertyStateIndex(index: any) {
    this.activePropertyStateIndex = index
    this.availability = this.propStatus[index]
    this.ngOnInit()
  }

  pageChange(event: any) {
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: {
        p: event
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    this.ngOnInit()
  }


  navToProp(id: any) {
    this.router.navigate(['' + id], { relativeTo: this.activeRoute })
  }


}
