import { DataService } from 'src/app/services/data.service';
import { MockApiService } from './../../services/mock-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  assetsMetrics: any = []
  usageMetrics: any;
  feed: any;

  user = localStorage.getItem('user') as any

  user_obj = JSON.parse(this.user)


  date = new Date().toDateString()
  constructor(private mockapi: MockApiService, private data: DataService) { }

  ngOnInit(): void {

    this.data.getStatsForDashboard().then(res=>{
      this.assetsMetrics = (res as any).data
    })

    this.mockapi.getFeed().then(feed=>{
      this.feed = feed
    })






  }




}
