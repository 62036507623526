import { BookingsComponent } from './realestate/home/bookings/bookings.component';
import { NologinGuard } from './guards/nologin.guard';
import { LoginGuard } from './guards/login.guard';
import { HomeComponent } from './realestate/home/home.component';
import { DashboardComponent } from './realestate/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArtisanComponent } from './artisan/artisan.component';
import { LoginComponent } from './login/login.component';
import { RealestateComponent } from './realestate/realestate.component';
import { TeamComponent } from './realestate/home/team/team.component';
import { PropertiesComponent } from './realestate/home/properties/properties.component';
import { PropertyDetailsComponent } from './realestate/home/properties/property-details/property-details.component';
import { KeyFacilitationComponent } from './realestate/home/key-facilitation/key-facilitation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/u/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate:[NologinGuard],

  },
  {
    path: 'u',
    component: RealestateComponent,
    canActivate:[LoginGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path:'bookings',
        component:BookingsComponent
      },
      {
        path:'team',
        component:TeamComponent
      },
      {
        path:'key-facilitation',
        component:KeyFacilitationComponent
      },
      {
        path:'properties',
        component:PropertiesComponent
      },
      {
        path:'properties/:id',
        component:PropertyDetailsComponent
      }
    ]
  },
  {
    path: 'artisan',
    component: ArtisanComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
