import { UiService } from './services/ui.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showLoader = false;
  title = 'afciti';

  constructor(private ui:UiService){
    this.ui.$showLoader.subscribe((shouldShowLoader: boolean) => {
      this.showLoader = shouldShowLoader
    })
  }
}
