import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InterceptorService } from './services/interceptor.service';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RealestateComponent } from './realestate/realestate.component';
import { ArtisanComponent } from './artisan/artisan.component';
import { DashboardComponent } from './realestate/dashboard/dashboard.component';
import { StatsCardComponent } from './realestate/dashboard/stats-card/stats-card.component';
import { FeedCardComponent } from './realestate/dashboard/feed-card/feed-card.component';
import { HomeComponent } from './realestate/home/home.component';
import { NgbDropdownModule, NgbModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PropertyLotCardComponent } from './realestate/home/property-lot-card/property-lot-card.component';
// import { NgIconsModule } from '@ng-icons/core';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { BookingCardComponent } from './realestate/home/booking-card/booking-card.component';
import { UserCardComponent } from './realestate/home/user-card/user-card.component';
import { BookingsComponent } from './realestate/home/bookings/bookings.component';
import { BookCardComponent } from './realestate/home/bookings/book-card/book-card.component';
import { DateCardComponent } from './realestate/home/bookings/date-card/date-card.component';
import { AgmCoreModule } from '@agm/core';
import { TeamComponent } from './realestate/home/team/team.component';
import { TeamUserCardComponent } from './realestate/home/team/team-user-card/team-user-card.component';
import { NotifCardComponent } from './realestate/home/team/notif-card/notif-card.component';
import { PropertiesComponent } from './realestate/home/properties/properties.component';
import { PropertyCardComponent } from './realestate/home/properties/property-card/property-card.component';
import { PropertyDetailsComponent } from './realestate/home/properties/property-details/property-details.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BookinDetailCardComponent } from './realestate/home/bookings/bookin-detail-card/bookin-detail-card.component';
import { MapComponent } from './realestate/home/bookings/bookin-detail-card/map/map.component';
// import { KeyConsolidationComponent } from './realestate/home/key-consolidation/key-consolidation.component';
import { KeyFacilitationComponent } from './realestate/home/key-facilitation/key-facilitation.component';
import { KfCardComponent } from './realestate/home/key-facilitation/kf-card/kf-card.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RealestateComponent,
    ArtisanComponent,
    DashboardComponent,
    StatsCardComponent,
    FeedCardComponent,
    HomeComponent,
    PropertyLotCardComponent,
    BookingCardComponent,
    UserCardComponent,
    BookingsComponent,
    BookCardComponent,
    DateCardComponent,
    TeamComponent,
    TeamUserCardComponent,
    NotifCardComponent,
    PropertiesComponent,
    PropertyCardComponent,
    PropertyDetailsComponent,
    BookinDetailCardComponent,
    MapComponent,
    // KeyConsolidationComponent,
    KeyFacilitationComponent,
    KfCardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbDropdownModule,
    SharedModule,
    NgbModule,
    NgxGalleryModule,
    NgbPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),

    NgbTooltipModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyD3N85RgoZ5FBC0Ojvj5jd4L--I2upiWEs',
      // apiKey:'AIzaSyC1crwF3bFRZoqpo5OzFH--JX_G9OJWyDo',
      apiKey:'AIzaSyAHHoifTiYGFmHWMKXRZGcmCT7Kj5nfcFk',
      libraries: ['places']
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      
    }),
    // NgIconsModule.withIcons({  }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
