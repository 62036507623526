<div class="body ps-3">

    <div class=" mb-2">
        <div class="flex align-items-center flex-wrap card-container green-container row">

            <div class="col-lg-5 col-md-4 mb-2">
                <button style="font-size: 10px;" pButton type="button"
                    class="text-sm px-3 py-2 blue-bg caption text-button" (click)="back()" label="Back"></button>
                <h5 class="caption black-text fs-5 d-inline ms-4">Details</h5>
            </div>
            <div class="col-lg-7 col-md-8 text-right row">

            </div>


        </div>

    </div>


    <div>
        <div style="background-color: white;">

            <div>
                <div class="p-2 pt-4 pb-4 " style="background-color: white;height:90vh;overflow-y: scroll;">

                    <div *ngIf="property" class="row">
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <div class="mt" style="width: 100%;overflow: scroll;">
                                <ngx-gallery style="width: 100%;" [options]="galleryOptions" [images]="galleryImages"
                                    class="ngx-gallery"></ngx-gallery>
                            </div>

                        </div>

                        <div *ngIf="property" class="col-xl-5 col-lg-5 col-md-6 ps-4 pe-4">
                            <div class="row px-3 justify-content-center align-items-center">
                                <div class="col-sm-auto shadow-sm text-center p-3 rounded me-2 d-none d-sm-block">
                                    <i class="bi property-type-icon bi-houses"></i>
                                    <p class="property-type">{{property.type_data.name}}</p>
                                </div>
                                <div class="col ps-3 pe-5">
                                    <p class="property-title black-text mt-1 krona">{{property.title}}</p>
                                    <p class="property-cost black-text mb-1 mt-1">{{property.price | currency:'GHC '}}
                                    </p>
                                    <div class="row mx-2 align-items-center justify-content-start">
                                        <div style="width: fit-content!important;"
                                            class="row mt-3 no-gutters bordered  p-1 col-lg-auto col-md-12 align-items-center justify-content-center">
                                            <div class="col-auto m-0 p-0">
                                                <img *ngIf="property.landlord_data" [src]="property.landlord_data.photo"
                                                    alt="#" width="30" height="30" class="rounded-circle">
                                            </div>
                                            <div class="col me-3">
                                                <p class="gray-caption small-text">LandLord</p>
                                                <p *ngIf="property.landlord_data" class="small-text black-text caption">
                                                    {{property.landlord_data.first_name}}
                                                    {{property.landlord_data.last_name}}
                                                </p>
                                            </div>
                                            <div class="col-auto d-flex m-0 p-0 me-2">

                                                <i class="bi bi-telephone-fill blue-text cursor"></i>
                                            </div>
                                        </div>
                                        <div class="col-1">

                                        </div>
                                        <div class="col-auto d-flex align-items-center justify-content-end p-0 pt-3 ">
                                            <i class="bi bi-geo-alt-fill blue-text px-3 cursor"></i>
                                        </div>

                                        <div
                                            class="col-auto d-flex align-items-center p-0  justify-content-center pt-3">
                                            <i class="bi bi-telephone-fill blue-text cursor"></i>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-sm-auto m-0 p-0">

                                </div>
                            </div>

                            <hr style="border-color: #3B59EE!important;border-width: 1px;"
                                class=" opacity-100 mt-4 mb-2">

                            <div class="row">
                                <div class="text-left p-2 rounded  d-inline-block col-auto me-4">
                                    <p class="small-text gray-caption">Added</p>
                                    <p class="med-text">{{property.createdAt | date:'dd' }}</p>
                                    <p class="med-text">{{property.createdAt | date:'MMM' }}</p>
                                    <p class="med-text">{{property.createdAt | date:'YYYY' }}</p>
                                </div>
                                <div class="text-left p-2 rounded me-1 d-inline-block col-auto me-4">
                                    <p class="small-text gray-caption">Submitted By</p>
                                    <div style="width: fit-content;"
                                        class="row mt-3 no-gutters bordered  p-1 me-4 col align-items-center justify-content-center">
                                        <div class="col-auto m-0 p-0">
                                            <img *ngIf="property.landlord_data" [src]="property.landlord_data.photo"
                                                alt="#" width="30" height="30" class="rounded-circle">
                                        </div>
                                        <div class="col me-3">
                                            <p class="gray-caption small-text p-0">Agent</p>
                                            <p *ngIf="property.landlord_data" class="small-text black-text caption">
                                                {{property.landlord_data.first_name}}
                                                {{property.landlord_data.last_name}}
                                            </p>
                                        </div>
                                        <div class="col-auto d-flex m-0 p-0 me-2">

                                            <i class="bi bi-telephone-fill blue-text cursor"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-left p-2 rounded me-1 d-inline-block col-auto me-2">
                                    <p class="small-text gray-caption text-left">Availability</p>
                                    <p class="med-text">{{property.availability}}</p>


                                </div>
                                <div class="text-left p-2 rounded me-1 d-inline-block col-auto">
                                    <p class="small-text gray-caption text-left">Publish Status</p>
                                    <p class="med-text">{{property.status}}</p>
                                    <!-- <p> -->
                                    <span (click)="publish(true)" *ngIf="property.status=='Unpublished'"
                                        class="badge rounded-pill blue-bg px-3 py-2 cursor small-text mt-2">Publish</span>
                                    <span (click)="publish(false)" *ngIf="property.status=='Published'"
                                        class="badge rounded-pill blue-bg px-3 py-2 cursor small-text mt-2">Unpublish</span>
                                    <!-- </p> -->
                                </div>
                            </div>


                            <hr style="border-color: #a1a1a1!important;border-width: 0.1px;" class=" opacity-100 my-4">

                            <div class="row">
                                <div class="text-left p-2 rounded me-1 d-inline-block col-auto">
                                    <p class="small-text gray-caption text-left">Release Type</p>
                                    <p class="med-text">{{property.let_off_type}}</p>
                                    <p class="small-text caption blue-text">Updated at: {{property.let_of_type_change_date | date:'dd/MM/yy' }}</p>

                                    <!-- <p>
                                        <span
                                            class="badge rounded-pill blue-bg px-3 py-2 cursor small-text mt-2">Change</span>
                                    </p> -->
                                </div>
                                <div class="text-left p-2 rounded me-1 d-inline-block col-auto">
                                    <p class="small-text gray-caption text-left">Release Tenure</p>
                                    <p class="med-text">{{property.tenure}} Years</p>

                                    <p class="small-text caption blue-text">Updated at: {{property.tenure_change_date | date:'dd/MM/yy' }}</p>
                                    <!-- <p>
                                        <span
                                            class="badge rounded-pill blue-bg px-3 py-2 cursor small-text mt-2">Change</span>
                                    </p> -->
                                </div>
                            </div>

                            <p class="med-text mt-2 text-justify">{{property.description}}</p>
                            <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-100 my-4">


                            <div class="row px-3 mt-4 mb-5">
                                <p class="black-text property-title">Amenities</p>

                                <ng-container *ngIf="updateAmenities">
                                    <!-- <ng-container> -->
                                    <ng-multiselect-dropdown class=" mt-3" [placeholder]="'Select amenities'"
                                        [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                                        (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                                    </ng-multiselect-dropdown>
                                    <!-- <span (click)="saveAmenities()" style="width: fit-content;"
                                        class="badge rounded-pill blue-bg py-2 px-3 m-1 small-text cursor mt-2">Update</span> -->
                                </ng-container>


                                <ng-container *ngIf="!updateAmenities">
                                    <div class=" px-2 py-2 border rounded mt-3">
                                        <span *ngFor="let a of property.amenities_data"
                                            class="badge rounded-pill gray-pill py-2 px-3 m-1 small-text">{{a.name}}</span>
                                    </div>
                                    <!-- <span (click)="updateAmenities=true" style="width: fit-content;"
                                        class="badge rounded-pill blue-bg py-2 px-3 m-1 small-text cursor mt-2">Update</span> -->
                                </ng-container>






                            </div>


                            <div class="row px-3 mt-2 justify-content-center align-items-center">
                                <p class="black-text property-title">Details</p>
                                <div class="px-1 py-1 border rounded mt-3">
                                    <div class="text-center p-2 rounded me-1 d-inline-block">
                                        <i class="bi bi-building property-deets-icon"></i>
                                        <p class="small-text">{{property.type_data.name}}</p>
                                    </div>
                                    <div *ngIf="(property.apartment_number)"
                                        class="text-center p-2 rounded me-1 d-inline-block">
                                        <p class="property-deets-icon m-0 p-0 caption">{{property.apartment_number}}</p>
                                        <p class="small-text">Apartment Number</p>
                                    </div>
                                    <div class="text-center p-2 rounded me-1 d-inline-block">
                                        <i class="bi bi-square  property-deets-icon"></i>
                                        <p class="small-text">3 Rooms</p>
                                    </div>
                                    <div class="text-center p-2 rounded me-1 d-inline-block">
                                        <i class="fas fa-shower property-deets-icon"></i>
                                        <p class="small-text">Bathroom</p>
                                    </div>
                                    <div *ngIf="(property.pet_allowed=='Yes')"
                                        class="text-center p-2 rounded me-1 d-inline-block">
                                        <i class="fas fa-paw  property-deets-icon"></i>
                                        <p class="small-text">Pets Allowed</p>
                                    </div>

                                    <div *ngIf="(property.landlord_in_bound=='Yes')"
                                        class="text-center p-2 rounded me-1 d-inline-block">
                                        <i class="bi bi-person  property-deets-icon"></i>
                                        <p class="small-text">Landlord In-Bound</p>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-12">
                            <div class="w-100 d-inline-block  m-0 pane-header">
                                <div class="gray-bg krona med-text blue-text p-3">
                                    <span>Viewings</span>
                                </div>
                                <div style="height: 280px;overflow: scroll;">
                                    <div class="cursor" (click)="showBooking(b.slug)" *ngFor="let b of property.appointments">
                                        <div class="d-inline-block px-4 py-1">
                                            <h5 class="caption fs-2 d-inline-block  mt-2">{{b.date | date :'dd' |
                                                uppercase }}</h5>
                                            <div class="mx-2 p d-inline-block">
                                                <span style="font-size: 8px;" class="p-0 caption gray-caption">{{b.date
                                                    | date :'EEEE' }}</span>
                                                <p style="line-height: 2px;" class="caption med-text mt-1">{{b.date |
                                                    date :'MMM yyyy' | uppercase}}</p>
                                            </div>
                                            <p class="caption small-text mt-3">PROSPECTS ATTENDING
                                                ({{b.prospects.length}})</p>
                                        </div>
                                        <hr style="border-color: #d5cfcf!important;border-width: 1px;"
                                            class=" opacity-100 mx-2">
                                    </div>
                                    <!-- <div>
                                            <div class="d-inline-block px-4 py-1">
                                                <h5 class="caption fs-2 d-inline-block  mt-2">12</h5>
                                                <div class="mx-2 p d-inline-block">
                                                    <span style="font-size: 8px;" class="p-0 caption gray-caption">Monday</span>
                                                    <p style="line-height: 2px;" class="caption med-text mt-1">Aug 2023</p>
                                                </div>
                                            </div>
                                            <hr style="border-color: #bfbaba!important;border-width: 1px;" class=" opacity-100 mx-4">
                                        </div> -->
                                </div>

                            </div>

                            <div class="w-100 d-inline-block  m-0 pane-header mt-3">
                                <div
                                    class="gray-bg krona med-text blue-text p-3 row justify-content-center align-items-center">
                                    <span class="col">PLA Agreement</span>
                                    <button (click)="updateTriggered=true" *ngIf="!updateTriggered"
                                        style="width: fit-content;"
                                        class="badge rounded-pill blue-bg py-2 px-3 m-1 small-text cursor mt-2">Update</button>
                                    <button *ngIf="updateTriggered" (click)="updatePLA()" style="width: fit-content;"
                                        class="badge rounded-pill blue-bg py-2 px-3 m-1 small-text cursor mt-2">Save</button>
                                </div>
                                <div class="p-3" style="height: 350px;overflow: scroll;">
                                    <textarea *ngIf="updateTriggered"
                                        [(ngModel)]="property.pla"
                                        style="font-size: 12px;text-align: justify;border: none;background-color: white;height: 100%;width: 100%;padding: 1px;"></textarea>
                                    <span *ngIf="!updateTriggered"
                                        style="font-size: 12px;text-align: justify;border: none;background-color: transparent;height: 100%;width: 100%;">{{property.pla}}</span>
                                </div>

                            </div>

                        </div>




                    </div>

                </div>

            </div>

        </div>


    </div>





</div>