<div class="row justify-content-center align-items-center">
    <div style="height: 100vh;background:url('../../assets/images/login_bg.jpg');background-size: cover;margin: 0;padding: 0;"
        class="col-lg-5 col-md-4 col-sm-3 d-none d-md-block">
        <div class="mask">

        </div>
    </div>
    <div class="col-lg-5 offset-lg-1 col-md-6 offset-md-1 p-5 justify-content-center align-items-center">
        <div class="container">
            <div class="">
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto m-0 p-0">
                        <img class="inline px-3" height="60px" src="../../assets/images/afciti.png">
                    </div>
                    <div class="col m-0 p-0">
                        <h5 style="font-size: 16px;" class="caption flex align-items-center justify-content-center my-5 blue-text">Location Manager</h5>
                    </div>
                </div>
              



                    <form [formGroup]="loginForm">
                        <div class="form-floating mb-2">
                            <input formControlName="email" type="email" class="form-control caption" id="floatingInput" placeholder="name@example.com">
                            <label style="font-size: 15px;" class="gray-caption" for="floatingInput">Username</label>
                        </div>
                        <div class="form-floating">
                            <input formControlName="password" type="password" class="form-control caption" id="floatingPassword" placeholder="Password">
                            <label style="font-size: 15px;" class="gray-caption" for="floatingPassword">Password</label>
                        </div>
                    </form>
                

                <div  class="row  align-items-center mt-5">
                    <div class="col-lg-2 col-md-3 col-3 m-0">

                        <button  (click)="login()" style="background-color: #3B59EE;height: 60px;width: 60px;color: white;"  class="btn btn-rounded btn-icon">
                            <i class="bi bi-chevron-right"></i>
                          </button>
                       
                    </div>
                    <div class="col-lg-10 col-md-9 col-9 p-0 m-0">
                        <hr style="border-color: #3B59EE!important;border-width: 0.2px;" class="border opacity-100">
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="col-lg-1 col-md-1"></div>

</div>
