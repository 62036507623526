import { ActivatedRoute, Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-book-card',
  templateUrl: './book-card.component.html',
  styleUrls: ['./book-card.component.css']
})
export class BookCardComponent implements OnInit {
  @Input('booking') booking: any;
   @Input('delegates') delegates:any;
   @Output('updatedAgent') updatedAgent = new EventEmitter<boolean>()

  users: any;

  // delegates: any;
  @ViewChild('myDrop', { static: true }) myDrop: any;

  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  private geoCoder:any;

  @ViewChild('search')
  public searchElementRef: ElementRef | undefined;

 @ViewChild('bookingDetails')  bookingDetails:any



 prospets:any;;



  constructor(private data: DataService, private ui: UiService,private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,private modalService: NgbModal,private router:Router,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    

    // this.data.getUserForLocationManager("delegates")?.then(users => {
    //   this.delegates = (users as any).data
    // })


    // this.booking.forEach((b: any) => {
    //   console.log(b.prospects)
    // });


  

    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef!.nativeElement);
    //   // alert(autocomplete)
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.zoom = 15;
    //       this.getAddress(this.latitude, this.longitude);

    //     });
    //   });
    // });
   

  }

  getUser(slug:any){
        this.data.getUser(slug).then(user=>{
          console.log(user)
        })
  }


  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        // this.getAddress(this.latitude, this.longitude);

      });
    }
  }


  markerDragEnd($event: any) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  updateDelegate(d: any) {
    this.data.assignDelegateToBooking(d.slug, this.booking.slug,0,0).then(res => {
      this.ui.showSuccess('Delegate Assigned')
    this.updatedAgent.emit(true)

    }, rej => {
      this.ui.hideLoader()
      this.ui.showError(rej.errors.message)
    }).catch(e => {

    })

    this.ngOnInit
  }


  getAddress(latitude:any, longitude:any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results:any, status:any) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 15;
          this.address ='...'+(results[0].formatted_address as string).substring((results[0].formatted_address as string).length-16);
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  

  selectLocation(){
    // alert('clikc')
    this.myDrop.close();
    console.log(this.latitude,this.longitude)
  }

  mapClicked($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }


  emit(slug:string){
    this.router.navigate([],{queryParams:{booking:slug}})
    
  }

}
  


  // clickedMarker(label: any, index: number) {
  //   console.log(`clicked the marker: ${label || index}`)
  // }



//   markerDragEnd($event: any) {
//     console.log('dragEnd',$event);
//   }


//   getAddress(latitude: number, longitude: number) {
//     this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
//       console.log(results);
//       console.log(status);
//       if (status === 'OK') {
//         if (results[0]) {
//           this.zoom = 12;
//           this.address = results[0].formatted_address;
//         } else {
//           window.alert('No results found');
//         }
//       } else {
//         window.alert('Geocoder failed due to: ' + status);
//       }

//     });
//   }

 
// }

// interface marker {
//   lat: number;
//   lng: number;
//   label?: string;
//   draggable: boolean;
// }




