<!-- <router-outlet></router-outlet>
<p-blockUI [style]="{'backgroundColor':'red'}" [blocked]="showLoader">
   
    <i class="bi bi-arrow-repeat loader"></i>
</p-blockUI> -->


<div style="height: 100vh;width: 100vw">
    <div
      style="position: absolute;height: 100%;width: 100%;top: 0;left: 0;margin: 0;">
      <router-outlet></router-outlet>
    </div>
  
    <div *ngIf=showLoader class="row justify-content-center align-items-center p-0 m-0"
      style="position: absolute;height: 100vh;width: 100vw;background-color: rgba(0, 0, 0, 0);text-align: center;z-index: 1000000000000000;;overflow: hidden;">
      <div class="col-12">
        <img style="height: 50px;width: 50px;" class="loader" src="../assets/images/android-chrome-512x512.png">
      </div>
    </div>
  </div>