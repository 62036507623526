<div class="body ps-3">




    <div class="mb-2">
        <div class="flex align-items-center flex-wrap card-container green-container row">

            <div class="col-lg-5 col-md-4 mb-2">
                <button style="font-size: 10px;" pButton type="button"
                    class="text-sm px-3 py-2 blue-bg caption text-button" disabled="true" label="Real Estate"></button>
            </div>
            <div class="col-lg-7 col-md-8 text-right row">
                <div class="col mb-2">
                    <input placeholder="search" class="afciti-search shadow-sm ">
                </div>
                <div class="col-sm-auto">
                    <ul style="background-color: white;" class="nav nav-pills p-1 shadow-sm rounded">
                        <li class="nav-item me-1 cursor">
                            <a class="nav-link caption text-sm" (click)="toggleIndex(0)"
                                [ngClass]="activeIndex==0?'active':''">Property</a>
                        </li>
                        <li class="nav-item cursor">
                            <a class="nav-link caption text-sm" (click)="toggleIndex(1)"
                                [ngClass]="activeIndex==1?'active':''">Tenants</a>
                        </li>
                    </ul>
                </div>

            </div>


        </div>
    </div>


    <div style="max-height: 90vh;overflow-y: scroll;" class="row mt-2 pt-2 g-2">
        <div class="col-xl-4 col-md-6 rounded">
            <div class="pane rounded shadow-sm">
                <div class="pane-header px-3 pt-3">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-auto">
                            <h5 class="caption pane-caption black-text">Bookings</h5>
                        </div>
                        <div class="col">
                            <input placeholder="search" class="afciti-search">
                        </div>
                        <div class="col-auto">
                            <i class="bi bi-sort-up fs-3 blue-text cursor"></i>
                        </div>
                    </div>
                    <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-100">
                    <ul class="nav nav-pills p-0 m-0 mt-1">
                        <li class="nav-item">
                            <a (click)="switchBookingTab(0)" [ngClass]="selectedBookingTab==0?'subtab-active':''"
                                class="nav-link caption black-text   p-0 me-4 pb-3 pt-1 px-1 cursor">Unattended</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="switchBookingTab(1)" [ngClass]="selectedBookingTab==1?'subtab-active':''"
                                class="nav-link caption gray-caption   p-0  pb-3 pt-1 px-1 cursor">Attended</a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="pt-3 px-2" style="background-color: white;height: 70vh;overflow-y: scroll;">

                <ng-container *ngIf="landingBookings">
                    <app-booking-card (updatedAgent)="reload()" [delegates]="delegates" [booking]="booking"
                        *ngFor="let booking of selectedBookings"></app-booking-card>
                </ng-container>


            </div>
            <div style="width: 100%;background-color: #3B59EE;" (click)="navTo('bookings')"
                class="float-bottom  cursor text-center justify-content-center mx-auto my-auto">
                <p style="font-size: 10px;color: white;" class="py-3 caption cursor">See More</p>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 rounded">
            <div class="pane rounded shadow-sm">
                <div class="pane rounded shadow-sm">
                    <div class="pane-header px-3 pt-3">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-auto">
                                <h5 class="caption pane-caption black-text">Team</h5>
                            </div>
                            <div class="col">
                                <input placeholder="search" class="afciti-search">
                            </div>
                            <div class="col-auto">
                                <i class="bi bi-sort-up fs-3 blue-text cursor"></i>
                            </div>
                        </div>
                        <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-100">
                        <ul style="overflow:auto;white-space: nowrap" class="nav nav-pills p-0 m-0 mt-1">
                            <li class="nav-item d-inline">
                                <a (click)="selectUser('delegates')"
                                    [ngClass]="selectedUserTab=='delegates'?'subtab-active black-text':'gray-caption'"
                                    class="nav-link caption p-0 me-0 pb-3 pt-1 px-1 cursor">Delegates</a>
                            </li>
                            <li class="nav-item d-inline">
                                <a [ngClass]="selectedUserTab=='agents'?'subtab-active black-text':'gray-caption'"
                                    (click)="selectUser('agents')"
                                    class="nav-link caption    p-0 me-0 pb-3 pt-1 px-1 cursor">Agents</a>
                            </li>
                            <li class="nav-item d-inline">
                                <a [ngClass]="selectedUserTab=='landlords'?'subtab-active black-text':'gray-caption'"
                                    (click)="selectUser('landlords')"
                                    class="nav-link caption    p-0 me-1 pb-3 pt-1 px-1 cursor">Landlords</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="pt-3 px-1" style="background-color: white;height: 70vh;overflow-y: scroll;">

                <app-user-card [user]="user" *ngFor="let user of selectedUsers"></app-user-card>



            </div>
            <div style="width: 100%;background-color: #3B59EE;" (click)="navTo('team')"
                class="float-bottom  text-center cursor justify-content-center mx-auto my-auto">
                <p style="font-size: 10px;color: white;" class="py-3 caption cursor">See More</p>
            </div>

        </div>
        <div class="col-xl-5 rounded">
            <div class="pane rounded shadow-sm">
                <div class="pane-header px-3 pt-3">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-auto">
                            <h5 class="caption pane-caption black-text">Property Lot</h5>
                        </div>
                        <div class="col">
                            <input placeholder="search" class="afciti-search">
                        </div>
                        <div class="col-auto">
                            <i class="bi bi-sort-up fs-3 blue-text cursor"></i>
                        </div>
                    </div>
                    <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-100">
                    <ul *ngIf="!showPropDeets" class="nav nav-pills p-0 m-0 mt-1 ">
                        <li class="nav-item">
                            <a class="nav-link caption black-text  subtab-active p-0 me-4 pb-3 pt-1 px-1 cursor">All</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link caption gray-caption p-0  me-4 pb-3 pt-1 px-1 cursor">Popular</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link caption gray-caption  p-0 me-4  pb-3 pt-1 px-1 cursor">Featured</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link caption gray-caption  p-0  pb-3 pt-1 px-1 cursor">Deals</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="!showPropDeets" class="pt-3 px-2"
                style="background-color: white;min-height: 70vh;max-height: 70vh;overflow-y: scroll;">



                <ng-container *ngIf="landingProperties">
                    <app-property-lot-card (click)="showDeets(property)" [property]="property"
                        *ngFor="let property of landingProperties.data"></app-property-lot-card>
                </ng-container>
            </div>
            <div [@inOutAnimation] *ngIf="showPropDeets" class="pt-3 px-3"
                style="background-color: white;max-height: 80vh;overflow-y: scroll;">

                <p (click)="hideDeets()" class="text-end"><i class="bi bi-x-lg blue-text fs-5 cursor m-0"></i></p>


                <div class="row px-3 justify-content-center align-items-center">
                    <div class="col-sm-auto shadow-sm text-center p-3 rounded me-2 d-none d-sm-block">
                        <i class="bi property-type-icon"
                            [ngClass]="selectedProp.type_data.name=='Office'?'bi-building':'bi-houses'"></i>
                        <p class="property-type">{{selectedProp.type_data.name}}</p>
                    </div>
                    <div class="col ps-3 pe-5">
                        <p class="property-title caption black-text mt-1">{{selectedProp.title}}</p>
                        <p class="property-cost caption black-text mb-1 mt-1">{{selectedProp.price | currency:'GHC '}}</p>
                        <div style="width: fit-content;" class="row mt-3 no-gutters bordered  p-1 pe-2">
                            <div class="col-auto m-0 p-0">
                                <img *ngIf="selectedProp.landlord_data" [src]="selectedProp.landlord_data.photo" alt="#"
                                    width="30" height="30" class="rounded-circle">
                            </div>
                            <div class="col me-4">
                                <p class="gray-caption small-text">LandLord</p>
                                <p *ngIf="selectedProp.landlord_data" class="medium-text black-text caption">
                                    {{selectedProp.landlord_data.first_name}} {{selectedProp.landlord_data.last_name}}
                                </p>
                            </div>
                            <div class="col-auto d-flex m-0 p-0">
                                <i class="bi bi-geo-alt-fill blue-text px-3 cursor"></i>
                                <i class="bi bi-telephone-fill blue-text cursor"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-auto m-0 p-0">
                        <span (click)="viewProperty(selectedProp.slug)"
                            class="badge rounded-pill blue-bg px-3 py-2 cursor small-text mt-1">View</span>
                    </div>
                </div>

                <div class="mt-4" style="width: 100%;overflow: scroll;">
                    <ngx-gallery style="width: 100%;" [options]="galleryOptions" [images]="galleryImages"
                        class="ngx-gallery"></ngx-gallery>

                </div>



                <div class="row px-3 mt-3 justify-content-center align-items-center">
                    <p class="black-text property-title">Details</p>
                    <div class="px-1 py-1 border rounded mt-3">
                        <div class="text-center p-2 rounded me-1 d-inline-block">
                            <i class="bi bi-building property-deets-icon"></i>
                            <p class="small-text">{{selectedProp.type_data.name}}</p>
                        </div>
                        <div *ngIf="selectedProp.apartment_number" class="text-center p-2 rounded me-1 d-inline-block">
                            <p class="property-deets-icon m-0 p-0 caption">{{selectedProp.apartment_number}}</p>
                            <p class="small-text">Apartment Number</p>
                        </div>
                        <div class="text-center p-2 rounded me-1 d-inline-block">
                            <i class="bi bi-square  property-deets-icon"></i>
                            <p class="small-text">3 Rooms</p>
                        </div>
                        <div class="text-center p-2 rounded me-1 d-inline-block">
                            <i class="fas fa-shower property-deets-icon"></i>
                            <p class="small-text">Bathroom</p>
                        </div>
                        <div *ngIf="(selectedProp.pet_allowed=='Yes')"
                            class="text-center p-2 rounded me-1 d-inline-block">
                            <i class="fas fa-paw  property-deets-icon"></i>
                            <p class="small-text">Pets Allowed</p>
                        </div>

                        <div *ngIf="(selectedProp.landlord_in_bound=='Yes')"
                            class="text-center p-2 rounded me-1 d-inline-block">
                            <i class="bi bi-person  property-deets-icon"></i>
                            <p class="small-text">Landlord In-Bound</p>
                        </div>

                    </div>
                </div>

                <div class="row px-3 mt-4 mb-5">
                    <p class="black-text property-title">Amenities</p>

                    <div class="d-flex px-2 py-2 border rounded mt-3">
                        <span *ngFor="let a of selectedProp.amenities_data"
                            class="badge rounded-pill gray-pill py-2 px-3 m-1 small-text">{{a.name}}</span>

                    </div>

                    <p class="small-text mt-3 text-justify">{{selectedProp.description}}</p>
                </div>

            </div>

            <div *ngIf="!showPropDeets" style="width: 100%;background-color: #3B59EE;" (click)="navTo('properties')"
                class="float-bottom  text-center justify-content-center mx-auto my-auto">
                <p style="font-size: 10px;color: white;" class="py-3 caption cursor">See More</p>
            </div>

        </div>
    </div>


</div>