<!-- <div  class="modal fade rounded" id="modal" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered">
        <div class="modal-content shadow-sm">
            <div class="modal-body m-0 p-0">
                <div class="col-12 p-0 m-0">
                    <img class="rounded" style="object-fit: cover;width: 100%;border-radius: 0px;"
                        height="170px!important" [src]="booking.property_data.image">
                </div>

                <div class="p-3">

                    <div class="row  align-items-center no-gutters p-1 mt-2">
                        <div class="col-auto row me-1 justify-content-center align-items-center">
                            <div class="col-auto m-0 pe-2 text-start">
                                <i class="far fa-calendar-check blue-text"></i>
                            </div>
                            <div class="col-auto m-0 p-0">
                                <p style="font-size: 11px;" class="m-0 p-0 caption">{{booking.date | date: 'dd MMM,
                                    YYYY'}}</p>
                            </div>
                        </div>
                        <div class="col-auto row ms-1 justify-content-center align-items-center">
                            <div class="col-auto m-0 pe-2">
                                <i class="far fa-clock blue-text"></i>
                            </div>
                            <div class="col-auto text-start m-0 p-0">
                                <p style="font-size: 11px;" class="m-0 p-0 caption">{{booking.time}}</p>
                            </div>
                        </div>
                    </div>

                    <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-100 mb-3 mt-3">

                    <div class="row my-3 p-2">

                        <div  ngbDropdown
                            class="mt-2 me-2 col-auto row g-0 p-1  justify-content-center align-items-center"
                            style="height: auto;background-color: #f9f9f9;border-radius: 30px;z-index: 10000;">
                            <div id="dropdownBasic1" ngbDropdownToggle class="col-auto"
                                style="height: 22px;width: 22px;border-radius: 50%;background-color: #3B59EE;"></div>
                            <div id="dropdownBasic1" ngbDropdownToggle
                                class="col px-2 justify-content-center align-items-center cursor">
                                <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">AGENT</p>
                                <p *ngIf="!booking.delegate" style="font-size: 9px;padding: 0px;margin: 0px;"
                                    class="caption">Choose</p>
                                <p *ngIf="booking.delegate" style="font-size: 9px;padding: 0px;margin: 0px;"
                                    class="caption">{{booking.delegate_data.first_name}}
                                    {{booking.delegate_data.last_name}}</p>
                            </div>

                            <div id="dropdownBasic1" ngbDropdownToggle class="col-auto">
                                <i class="bi bi-caret-down-fill  px-3  blue-text"></i>
                            </div>
                            <div *ngIf="delegates" class="pt-3 mt-1 shadow-lg"
                                style="background-color: #f9f9f9;border: none;z-index: 10000;" ngbDropdownMenu
                                aria-labelledby="dropdownBasic1">
                                <div *ngFor="let d of delegates" (click)="updateDelegate(d)" ngbDropdownItem
                                    class="my-2 cursor">
                                    <div class="row justify-content-center align-items-center g-3">
                                        <div class="col-auto">
                                            <img style="cursor: pointer;background:url('https://image-placeholder.com/images/actual-size/57x57.png');background-color: transparent;"
                                                [src]="d.photo" alt="" width="30" height="30" class="rounded-circle">
                                        </div>
                                        <div class="col">
                                            <p style="font-size: 9px;" class="property-title black-text caption m-0">
                                                {{d.first_name}} {{d.last_name}}</p>
                                        </div>
                                        <div class="col-auto">
                                            <div class="dot"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="row">
                                <div class="col">
                                    <div ngbDropdown  #myDrop="ngbDropdown" autoClose="inside" class="d-inline-block">
                                        <div class="mt-2 me-1 col-auto row g-0 p-1  justify-content-center align-items-center"
                                            style="height: auto;background-color: #f9f9f9;border-radius: 30px;z-index: 10000;">
                                            <div id="dropdownForm1" ngbDropdownToggle class="col-auto">
                                                <i style="height: 22px;width: 22px;"
                                                    class="b bi-geo-alt-fill text-center blue-text"></i>
                                            </div>
                                            <div id="dropdownForm1" ngbDropdownToggle
                                                class="col px-2 justify-content-center align-items-center cursor">
                                                <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">
                                                    LOCATION</p>
                                                <p *ngIf="!address" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                                                    Choose Location
                                                </p>
                                                <p *ngIf="address" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                                                   {{address}}
                                                </p>
                                            </div>
                                            <div id="dropdownForm1" ngbDropdownToggle class="col-auto">
                                                <i class="bi bi-caret-down-fill  px-3  blue-text"></i>
                                            </div>
                                        </div>

                                        <div style="width: 500px;transform: translate(-25vh,-25vw);" ngbDropdownMenu
                                            aria-labelledby="dropdownForm1">
                                            <form>
                                                <div>
                                                    <div class="form-group">
                                                        <input style="font-size: 10px;" type="text"
                                                            class="afciti-search"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            placeholder="Search" autocorrect="off" autocapitalize="off"
                                                            spellcheck="off" type="text" #search>
                                                    </div>
                                                    <agm-map [latitude]="latitude" [longitude]="longitude"
                                                        [zoom]="zoom">
                                                        <agm-marker [latitude]="latitude" [longitude]="longitude"
                                                            [markerDraggable]="true"
                                                            (dragEnd)="markerDragEnd($event)"></agm-marker>
                                                    </agm-map>
                                                    <div (click)="selectLocation()"  class="row cursor">
                                                        <div class="col"></div>
                                                        <div class="col-auto">
                                                            <p   style="font-size: 10px;z-index: -100000;" pButton type="button"
                                                            class="text-sm px-3 py-2 blue-bg m-2 cursor caption text-button"
                                                            disabled="false" label="Select Location"></p>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </form>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <p style="font-size: 7px;" class="mt-4 mb-2 caption">Prospects attending ({{booking.prospects.length}})</p>

                    <div *ngFor="let user of booking.prospects" style="height: auto;width: 170px;background-color: white;" class="shadow-sm rounded p-3 mt-3">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-auto">
                               
                            </div>
                            <div class="col text-end">
                                <p *ngIf="user" style="font-size: 8px;" class="blue-text caption">
                                    {{user.slug}}</p>
                            </div>
                        </div>
                       

                        <i class="fas fa-phone blue-text text-end mt-2 cursor"></i>
                    </div>

                </div>



            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->



<div class="row p-0 mx-5" style="background-color: transparent;width: auto;">
    <div style="background-color: white;" class="col p-0 m-0 rounded shadow-lg">
        <div style="height: auto;" class="d-block">
            <div class="col-12 p-0 m-0 position-relative">
                <img class="rounded position-absolute" style="object-fit: cover;width: 100%;border-radius: 0px;" height="170px!important"
                    [src]="booking.property_data.image">
                <div style="width: 100%;" class="row position-absolute mt-2">
                    <div class="col">

                    </div>
                    <div class="col-auto">
                        <span style="width: fit-content;" (click)="view()"
                        class="badge rounded-pill blue-bg py-2 px-3 m-1 small-text cursor mt-2 caption  float-right">View</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-4" style="background-color: white;height: fit-content;width: 100%;margin-top: 180px;">
            <div class="row  align-items-center justify-content-center align-items-center no-gutters px-3 mt-3">
                <div class="col-auto row me-1 justify-content-center align-items-center">
                    <div class="col-auto m-0 pe-2 text-start">
                        <i class="far fa-calendar-check blue-text"></i>
                    </div>
                    <div class="col-auto m-0 p-0">
                        <span style="font-size: 13px;" class="m-0 p-0 caption">{{booking.date | date: 'dd MMM,
                            YYYY'}}</span>
                    </div>
                </div>
                <div class="col-auto row ms-1 justify-content-center align-items-center">
                    <div class="col-auto m-0 pe-2">
                        <i class="far fa-clock blue-text"></i>
                    </div>
                    <div class="col-auto text-start m-0 p-0">
                        <p style="font-size: 13px;" class="m-0 p-0 caption">{{booking.time}}</p>
                    </div>
                </div>
                <div class="col row ms-1 justify-content-center align-items-center">
                    <div class="row mx-2 align-items-center justify-content-center">
                        <div style="width: fit-content!important;"
                            class="row mt-3 no-gutters bordered  p-1 col-lg-auto col-md-12 align-items-center justify-content-center">
                            <div class="col-auto m-0 p-0">
                                <img *ngIf="booking.property_data.landlord" [src]="booking.property_data.landlord.photo"
                                    alt="#" width="30" height="30" class="rounded-circle">
                            </div>
                            <div class="col-auto me-3">
                                <p style="font-size: 7px;" class="gray-caption small-text">LandLord</p>
                                <p style="font-size: 9px;" *ngIf="booking.property_data.landlord" class="small-text black-text caption">
                                    {{booking.property_data.landlord.first_name}}
                                    {{booking.property_data.landlord.last_name}}
                                </p>
                            </div>
                            <div class="col-auto d-flex m-0 p-0 me-2">
                                <i class="bi bi-geo-alt-fill blue-text px-3 cursor"></i>
                            </div>
                            <div class="col-auto d-flex m-0 p-0 me-2">
                                <i class="bi bi-telephone-fill blue-text cursor"></i>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-100 mb-3 mt-3">
        </div>

        <div class="row my-3 px-5">

            <div ngbDropdown class="mt-2 me-2 col-auto row g-0 p-1  justify-content-center align-items-center"
                style="height: auto;background-color: #f9f9f9;border-radius: 30px;z-index: 10000;">
                <div id="dropdownBasic1" ngbDropdownToggle class="col-auto"
                    style="height: 22px;width: 22px;border-radius: 50%;background-color: #3B59EE;"></div>
                <div id="dropdownBasic1" ngbDropdownToggle
                    class="col px-2 justify-content-center align-items-center cursor">
                    <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">AGENT</p>
                    <p *ngIf="!booking.delegate && !delegateChanged" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">Choose
                    </p>
                    <p *ngIf="booking.delegate" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                        {{booking.delegate_data.first_name}}
                        {{booking.delegate_data.last_name}}</p>
                        <p *ngIf="delegateChanged" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                            {{selectedDelegate.first_name}}
                            {{selectedDelegate.last_name}}</p>
                </div>

                <div id="dropdownBasic1" ngbDropdownToggle class="col-auto">
                    <i class="bi bi-caret-down-fill  px-3  blue-text"></i>
                </div>
                <div *ngIf="delegates" class="pt-3 mt-1 shadow-lg"
                    style="background-color: #f9f9f9;border: none;z-index: 10000;width: fit-content;" ngbDropdownMenu
                    aria-labelledby="dropdownBasic1">
                    <div *ngFor="let d of delegates" (click)="updateDelegate(d)" ngbDropdownItem class="my-2 cursor">
                        <div class="row justify-content-center align-items-center g-3">
                            <div class="col-auto">
                                <img style="cursor: pointer;background:url('https://image-placeholder.com/images/actual-size/57x57.png');background-color: white;"
                                    [src]="d.photo" alt="" width="30" height="30" class="rounded-circle">
                            </div>
                            <div class="col">
                                <p style="font-size: 8px;" class="property-title black-text caption m-0">
                                    {{d.first_name}} {{d.last_name}}</p>
                            </div>
                            <div class="col-auto">
                                <div class="dot"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="row">
                    <div class="col">
                        <div (click)="showMap()" ngbDropdown #myDrop="ngbDropdown" autoClose="inside" class="d-inline-block">
                            <div class="mt-2 me-1 col-auto row g-0 p-1  justify-content-center align-items-center"
                                style="height: auto;background-color: #f9f9f9;border-radius: 30px;z-index: 10000;">
                                <div id="dropdownForm1" ngbDropdownToggle class="col-auto">
                                    <i style="height: 22px;width: 22px;"
                                        class="b bi-geo-alt-fill text-center blue-text"></i>
                                </div>
                                <div id="dropdownForm1" ngbDropdownToggle
                                    class="col px-2 justify-content-center align-items-center cursor">
                                    <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">
                                        LOCATION</p>
                                    <p *ngIf="!address" style="font-size: 9px;padding: 0px;margin: 0px;"
                                        class="caption">
                                        Choose Location
                                    </p>
                                    <p *ngIf="address" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                                        {{address}}
                                    </p>
                                </div>
                                <div id="dropdownForm1" ngbDropdownToggle class="col-auto me-3">
                                    <!-- <i class="bi bi-caret-down-fill  px-3  blue-text"></i> -->
                                </div>
                            </div>

                            <!-- <div style="width: 500px;transform: translate(-25vh,-25vw);" ngbDropdownMenu
                                aria-labelledby="dropdownForm1">
                                <form>
                                    <div>
                                        <div class="form-group">
                                            <input style="font-size: 10px;" type="text" class="afciti-search"
                                                (keydown.enter)="$event.preventDefault()" placeholder="Search"
                                                autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
                                                #search>
                                        </div>
                                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                                            <agm-marker [latitude]="latitude" [longitude]="longitude"
                                                [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                                        </agm-map>
                                        <div (click)="selectLocation()" class="row cursor">
                                            <div class="col"></div>
                                            <div class="col-auto">
                                                <p style="font-size: 10px;z-index: -100000;" pButton type="button"
                                                    class="text-sm px-3 py-2 blue-bg m-2 cursor caption text-button"
                                                    disabled="false" label="Select Location"></p>
                                            </div>
                                        </div>

                                    </div>
                                </form>

                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row my-3 px-5 mb-5">
            <p style="font-size: 7px;" class="mt-4 mb-2 caption">Prospects attending ({{booking.prospects.length}})</p>

            <div *ngFor="let user of booking.prospects" style="height: auto;width: 170px;background-color: white;" class="shadow-sm rounded p-3 mt-3">
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <img *ngIf="user.photo" [src]="user.photo" style="background:url('https://image-placeholder.com/images/actual-size/57x57.png');background-color: transparent;"
                        alt="#" width="30" height="30" class="rounded-circle">
                    </div>
                    <div class="col text-end">
                        <p *ngIf="user" style="font-size: 8px;" class="blue-text caption">
                            {{user.slug}}</p>
                    </div>
                </div>
                <p class="caption small-text mb-2 mt-3">{{user.user.full_name}}</p>
               

                <div class="mt-3 row">
                    <div class="col text-left">
                        <i class="fas fa-check blue-text text-end  cursor"></i>
                    </div>
                    <div class="col text-end">
                        <i class="fas fa-phone blue-text text-end  cursor"></i>
                    </div>
                </div>
               
            </div>


            
        </div>
        <div *ngIf="delegateChanged && locationChanged" class="row cursor">
            <div class="col"></div>
            <div (click)="saveChanges()" class="col-auto cursor">
                <p style="font-size: 10px;z-index: -100000;" pButton type="button"
                    class="text-sm px-3 py-2 blue-bg m-2 cursor caption text-button" disabled="true"
                    label="Submit Changes"></p>
            </div>
        </div>
       


    </div>
    <div class="col-auto ms-3">
        <i (click)="close()" style="font-size: 30px;" class="fa fa-times cursor blue-text"></i>
    </div>

</div>