import { DataService } from './../../services/data.service';
import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookinDetailCardComponent } from './bookings/bookin-detail-card/bookin-detail-card.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.5s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class HomeComponent implements OnInit {
  activeIndex = 0
  landingProperties: any;
  landingBookings: any;
  showPropDeets = false
  showBookDeets = false

  selectedProp: any;
  selectedBooking: any;

  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  selectedBookingTab = 0

  attendedBookings: any;
  unAttendedBookings: any;

  selectedBookings: any;


  selectedUserTab = 'delegates';
  selectedUsers: any;


  delegates: any;


  navved = false


  constructor(
    private data: DataService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private ui: UiService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {


    this.activeRoute.queryParams.subscribe(r => {
      if (r.property) {
        this.data.getProperty(r.property).then(res => {
          this.selectedProp = res
          this.galleryImages = []
          this.makeImages(this.selectedProp)
          this.showPropDeets = true
        })

      } else if (r.booking) {
        this.data.getBooking(r.booking).then(res => {
          // console.log(res)
          this.selectedBooking = (res as any).data
          this.showBookDeets = true
          this.open()

        })


      }

      else {
        this.showPropDeets = false
        this.showBookDeets = false
      }
    })



    this.ui.showLoader()
    this.getProperties();

    this.galleryOptions = [

      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnailClasses: ['no-border']

      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailClasses: ['no-border']
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

  }

  private getProperties() {
    this.data.getProperties(1, null).then(resa => {

      this.landingProperties = (resa as any);

      this.data.getUserForLocationManager(this.selectedUserTab)?.then(users => {
        this.selectedUsers = (users as any).data;
        this.delegates = this.selectedUsers;

        this.getBookings();
      });

    });
  }

  private getBookings() {
    this.data.getBookings().then(resb => {

      this.landingBookings = resb;


      this.attendedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.status === 'Viewed';
      });

      this.unAttendedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.status === 'Pending';
      });

      this.selectedBookings = this.unAttendedBookings;
      this.ui.hideLoader();
    });
  }

  toggleIndex(index: number) {
    this.activeIndex = index
  }

  showDeets(property: any) {
    this.router.navigate([], { queryParams: { property: property.slug }, relativeTo: this.activeRoute })
    // this.selectedProp = property


    // this.galleryImages = []
    // this.makeImages(property)

    // this.showPropDeets = true
  }

  hideDeets() {
    this.router.navigate([], { relativeTo: this.activeRoute })
    // this.showPropDeets = false
  }


  makeImages(obj: any) {
    this.galleryImages.push({
      small: obj.image as any,
      medium: obj.image as any,
      big: obj.image as any
    })
    try {
      Object.values(obj.kitchen_images).forEach(value => {
        if ((value as string).startsWith('https')) {
          this.galleryImages.push(
            {
              small: value as any,
              medium: value as any,
              big: value as any
            }
          )
        }
      })
    }

    catch (error) {

    }

    try {
      Object.values(obj.bedroom_images).forEach(value => {
        if ((value as string).startsWith('https')) {
          this.galleryImages.push(
            {
              small: value as any,
              medium: value as any,
              big: value as any
            }
          )
        }
      })
    } catch (error) {

    }


    try {
      Object.values(obj.other_images).forEach(value => {
        if ((value as string).startsWith('https')) {
          this.galleryImages.push(
            {
              small: value as any,
              medium: value as any,
              big: value as any
            }
          )
        }
      })
    } catch (error) {

    }

  }

  switchBookingTab(index: any) {
    this.selectedBookingTab = index
    if (index == 0) {
      this.selectedBookings = this.unAttendedBookings
    } else {
      this.selectedBookings = this.attendedBookings
    }
  }

  selectUser(user: string) {
    this.selectedUserTab = user
    this.data.getUserForLocationManager(this.selectedUserTab)?.then(users => {
      this.selectedUsers = (users as any).data
    })
  }

  navTo(route: any) {
    // this.navved = true
    if (route == 'properties' || route == 'key-facilitation') {
      this.router.navigate([route], { relativeTo: this.activeRoute.parent, queryParams: { p: 1 } })
    }
    else {
      this.router.navigate([route], { relativeTo: this.activeRoute.parent })
    }

  }


  viewProperty(slug: any) {
    this.router.navigate(['properties/' + slug], { relativeTo: this.activeRoute.parent })
  }

  open() {
    const modal = this.modalService.open(BookinDetailCardComponent, { size: 'lg' });
    modal.componentInstance.booking = this.selectedBooking
    modal.componentInstance.delegates = this.delegates

    modal.result.then(
      (result) => {
        this.getBookings()
      },
      (reason) => {
        this.getBookings()
      },
    );


  }


  reload() {
    this.data.getBookings().then(resb => {

      this.landingBookings = resb

      this.attendedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.status === 'Viewed'
      });

      this.unAttendedBookings = this.landingBookings.data.filter(function (el: any) {
        return el.status === 'Pending'
      });

      this.selectedBookings = this.unAttendedBookings
      this.ui.hideLoader()
    })
  }
}


