import { urls } from './urls';
import { environment } from './../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  user: any;


  constructor(private http: HttpClient) {

  }





  login(body: any) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.base_url + urls.login, body).subscribe(res => {
        localStorage.setItem('user', JSON.stringify((res as any).data))
        resolve(res)
      }, rej => {
        // console.log()
        reject(rej.error)
      })
    })
  }

  getProperties(page: number,availability:any) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.properties + localStorage.getItem('user_id') + `?page=${page}&availability=${availability}`).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }

  getStatsForDashboard(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.stats + localStorage.getItem('user_id')).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }


  getAllProperties() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.properties + localStorage.getItem('user_id') + '?page=1&limit=10000000000000').subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }


  getProperty(propertyId:any) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.property + propertyId).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }


  getBookings() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.bookings + localStorage.getItem('user_id')).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }

  getCalender(month:number,year:number){
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.calendar+`month=${month}&year=${year}`).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }


  getBooking(slug:string){
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.get_booking + slug).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }


  getUser(slug:any){
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.user + slug).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }

  getUserForLocationManager(userType: string) {
    switch (userType) {
      case 'delegates':
        return new Promise((resolve, reject) => {
          this.http.get(environment.base_url + urls.delegates + localStorage.getItem('user_id')).subscribe(res => {
            resolve(res)
          }, rej => {
            reject(rej.error)
          })
        });
      case "landlords":
        return new Promise((resolve, reject) => {
          this.http.get(environment.base_url + urls.landlords + localStorage.getItem('user_id')).subscribe(res => {
            resolve(res)
          }, rej => {
            reject(rej.error)
          })
        });
      default:
        return new Promise((resolve, reject) => {
          resolve([])
        });
    }
  }


  assignDelegateToBooking(delegateSlug:any,bookingSlug:any,lng:any,lat:any){
    return new Promise((resolve, reject) => {
      this.http.put(environment.base_url + urls.assign_delegate,{"slug":bookingSlug,"delegate":delegateSlug,"longitude":String(lng),"latitude":String(lat)}).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })

  }


  setPublishStatus(slug:string,status:string){
    return new Promise((resolve, reject) => {
      this.http.put(environment.base_url + urls.set_publish_status,{"slug":slug,"status":status}).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }

  getAmenities(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.base_url + urls.get_amenities).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }

  updateAmenties(slug:string,amenities:string[]){
    return new Promise((resolve, reject) => {
      this.http.put(environment.base_url + urls.update_amenities,{"slug":slug,"amenities":amenities}).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }

  updatePLA(slug:string,pla:string[]){
    return new Promise((resolve, reject) => {
      this.http.put(environment.base_url + urls.update_pla,{"slug":slug,"pla":pla}).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }


  updateMeetupLocation(slug: any,lat: number,lng: number){
    return new Promise((resolve, reject) => {
      this.http.put(environment.base_url + urls.update_meetup_location,{"slug":slug,"longitude":lng.toString(),"latitude":lat.toString()}).subscribe(res => {
        resolve(res)
      }, rej => {
        reject(rej.error)
      })
    })
  }
}
