import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  selectedBookingTab = 0;
  selectedUsers:any;
  selectedUserTab = "delegates"
  activeIndex = 0;

  constructor(private data:DataService) { }

  ngOnInit(): void {
    this.data.getUserForLocationManager(this.selectedUserTab)?.then(users => {
      this.selectedUsers = (users as any).data
     
  })
}

  switchBookingTab(index: any) {
    this.selectedBookingTab = index
    
  }

  selectUser(user: string) {
    this.selectedUserTab = user
    this.data.getUserForLocationManager(this.selectedUserTab)?.then(users => {
      this.selectedUsers = (users as any).data
    })
  }


  toggleIndex(index: number) {
    this.activeIndex = index
  }

  

}
