import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router) {

  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve,reject)=>{
      setTimeout(()=>{
        var token = localStorage.getItem('access_token')
  
        if(this.validateToken(token)){
          resolve(true)
        }else{
          this.router.navigateByUrl('login')
          resolve(false)
        }
       
      },50)
      
    })
   
   
  }


  validateToken(token: any) {
    try {
      const payload = this.parse_jwt(token);
      const expires = new Date(payload.exp * 1000);
      const expired = (Date.now() >= payload.exp * 1000);
      return !expired;
    }
    catch (e) {
      return false;
    }
  }

  parse_jwt(jwt: string): any {
    if (jwt !== null && typeof jwt == 'string') {
      try {
        return JSON.parse(atob(jwt.split('.')[1]));
      }
      catch (e) {
        return null;
      }
    }
    return null;
  }

  
}
