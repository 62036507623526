import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.css']
})
export class BookingCardComponent implements OnInit {
  @Input('booking') booking:any;
  @Input('delegates') delegates:any;
  @Output('updatedAgent') updatedAgent = new EventEmitter<boolean>()


  // delegates:any;
  constructor(private data:DataService,private ui:UiService,private router:Router) { }

  ngOnInit(): void {
    // this.data.getUserForLocationManager("delegates")?.then(users=>{
    //   this.delegates = (users as any).data
    //  })
  }

  updateDelegate(d:any){
    this.data.assignDelegateToBooking(d.slug,this.booking.slug,0,0).then(res=>{
      this.ui.showSuccess('Delegate Assigned')
      this.updatedAgent.emit(true)
    },rej=>{
      this.ui.hideLoader()
      this.ui.showError(rej.errors.message)
    }).catch(e=>{
      
    })

  }

  navToProperty(){
    // this.router.navigate(['properties/'+],{relativeTo:this.activeRoute.parent})
  }

  emit(slug:string){
    // alert('slug')
    this.router.navigate([],{queryParams:{booking:slug}})
    
  }

}
