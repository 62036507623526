<div type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#user'+user.slug" style="width: 300px;" class="shadow-sm rounded m-2 p-3 mx-2 mb-4 d-inline-block">
<!-- <div type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#user1'" style="width: 300px;" -->
    <!-- class="shadow-sm rounded m-2 p-3 mx-2 mb-4 d-inline-block"> -->
    <div class="row justify-content-center cursor align-items-center">
        <div class="col-auto">
            <img style="cursor: pointer;background:url('https://image-placeholder.com/images/actual-size/57x57.png');background-color: transparent;"
                [src]="user.photo" alt="" width="60" height="60" class="rounded-circle">
        </div>
        <div class="col">
            <p class="property-title black-text caption m-0">{{user.first_name}} {{user.last_name}}</p>
        </div>
        <div class="col-auto">
            <div class="dot"></div>
        </div>

    </div>
</div>

<!-- <div  class="modal fade rounded" [id]="'user'+user.id" data-bs-backdrop="static" -->
<div class="modal fade rounded" [id]="'user'+user.slug" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content shadow-sm">
            <div style="height: 500px;background-color: white;" class="modal-body m-0 p-0 shadow-sm rounded px-3 py-2">
                <div class="row">
                    <div class="col">
                    </div>
                    <div class="col-auto">
                        <i data-bs-dismiss="modal" class="bi fs-5 cursor blue-text bi-x-lg"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                    </div>
                    <div class="col-auto">
                        <img  style="cursor: pointer;background:url('https://image-placeholder.com/images/actual-size/57x57.png')" [src]="user.photo" alt="" width="120"
                            height="120" class="rounded-circle">
                    </div>
                    <div class="col">
                    </div>
                </div>
                <div class="row g-1 align-items-center justify-content-center mt-3">

                    <div class="col  p-2">
                        <div style="border-radius: 40px;"
                            class="p-1 px-3 justify-content-center align-items-center  shadow-sm">
                            <p style="font-size: 6px;padding: 0px;margin: 0px;" class="caption">
                                FULL NAME</p>

                            <p style="font-size: 13px;padding: 0px;margin: 0px;" class="caption mt-1">
                                {{user.first_name}} {{user.last_name}}
                            </p>
                        </div>

                    </div>
                    <div class="col-auto  p-2 ">
                        <div style="border-radius: 40px;"
                            class="p-1 px-3  blue-bg justify-content-center align-items-center  shadow-sm cursor">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-auto pe-0 ps-3 m-0">
                                    <i style="height: 22px;width: 25px;color: white;font-size: 25px;"
                                        class="bi bi-person-vcard"></i>
                                </div>
                                <div class="col-auto">
                                    <!-- <p style="font-size: 6px;padding: 0px;margin: 0px;" class="caption">
                                        ID</p> -->

                                    <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption mt-1">
                                        View ID
                                    </p>
                                </div>
                            </div>





                        </div>

                    </div>

                </div>

                <div class="row m-0 mt-2 mb-2 p-0">
                    <div class="col p-0">
                        <p style="font-size: 10px;padding: 0px;margin: 0px;" class="caption my-2">
                            Contributions</p>
                    </div>
                    <div class="col"></div>
                    <div class="col p-0">
                        <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption mt-3">
                            License</p>
                    </div>
                </div>
                <div class="row m-0 g-2 justify-content-center align-items-center">

                    <div class="col rounded shadow-sm p-2">
                        <div class="row g-0 p-1  justify-content-center align-items-center"
                            style="border-radius: 30px;">
                            <div class="col-auto">
                                <i style="height: 22px;width: 22px;" class="b bi-house-fill text-center blue-text"></i>
                            </div>
                            <div class="col px-2 justify-content-center align-items-center cursor">
                                <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption blue-text">
                                    Properties
                                </p>
                            </div>
                        </div>
                        <div style="width: 100%;" class="justify-content-center align-items-center">
                            <h1 class="caption">12</h1>
                        </div>
                    </div>
                    <div class="col rounded shadow-sm p-2">
                        <div class="row g-0 p-1  justify-content-center align-items-center"
                            style="border-radius: 30px;">
                            <div class="col-auto">
                                <i style="height: 22px;width: 22px;"
                                    class="far fa-calendar-check text-center blue-text"></i>
                            </div>
                            <div class="col px-2 justify-content-center align-items-center cursor">
                                <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption blue-text">
                                    Schedules
                                </p>
                            </div>
                        </div>
                        <div style="width: 100%;" class="justify-content-center align-items-center">
                            <h1 class="caption">12</h1>
                        </div>
                    </div>
                    <div class="col">
                        <div style="width: 100%;"
                            class="shadow-sm blue-bg justify-content-center align-items-center p-3 rounded">
                            <h1 class="caption white">365</h1>
                            <p style="font-size: 9px;padding: 0px;margin: 0px;">Days Left</p>
                        </div>
                    </div>
                </div>

                <div class="row m-0 mt-2 mb-2  p-1">

                    <p style="font-size: 10px;padding: 0px;margin: 0px;" class="caption my-2">
                        Contact</p>
                    <div class="col-auto m-0 me-4 p-0 shadow-sm" style="border-radius: 30px;">
                        <div class="row g-0 p-2  justify-content-center align-items-center"
                            style="border-radius: 30px;">
                            <div class="col-auto">
                                <i style="height: 22px;width: 22px;"
                                    class="bi bi-telephone-fill text-center blue-text"></i>
                            </div>
                            <div class="col px-2 justify-content-center align-items-center cursor">
                                <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">PHONE NUMBER</p>
                                <!-- <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">{{user.mobile_number}}</p> -->
                                <p *ngIf="user.mobile_number" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">{{user.mobile_number}}</p>
                                <p *ngIf="user.phone_number" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">{{user.phone_number}}</p>


                                <!-- "phone_number": "123567", -->
                            </div>
                        </div>

                    </div>
                    <div class="col-auto m-0 p-0 shadow-sm" style="border-radius: 30px;">
                        <div class="row g-0 p-2  justify-content-center align-items-center"
                            style="border-radius: 30px;">
                            <div class="col-auto">
                                <i style="height: 22px;width: 22px;" class="bi bi-envelope text-center blue-text"></i>
                            </div>
                            <div class="col px-2 justify-content-center align-items-center cursor">
                                <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">EMAIL</p>
                                <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">{{user.email}}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row m-0 mt-2 mb-5  p-1">
                    <p style="font-size: 10px;padding: 0px;margin: 0px;" class="caption my-2">
                        Jurisdiction</p>
                        <div class="col m-0 me-4 p-0 shadow-sm" style="border-radius: 30px;">
                            <div class="row g-0 p-2  justify-content-center align-items-center"
                                style="border-radius: 30px;">
                                <div class="col-auto">
                                    <i style="height: 22px;width: 22px;"
                                        class="bi bi-geo-alt-fill text-center blue-text"></i>
                                </div>
                                <div class="col px-2 justify-content-center align-items-center cursor">
                                    <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">Area</p>
                                    <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">Teshie
                                    </p>
                                </div>
                            </div>
    
                        </div>
                        <div class="col-auto m-0 p-0 shadow-sm blue-bg" style="border-radius: 30px;">
                            <div class="row g-0 p-2  justify-content-center align-items-center"
                                style="border-radius: 30px;">
                                <div class="col-auto">
                                    <i style="height: 22px;width: 22px;" class="bi bi-arrow-clockwise white text-center"></i>
                                </div>
                                <div class="col px-2 justify-content-center align-items-center cursor">
                                    <!-- <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">EMAIL</p> -->
                                    <p style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">Request Transfer
                                    </p>
                                </div>
                            </div>
    
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>