import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UiService {


  $showLoader = new EventEmitter()

  
  constructor(private toastr: ToastrService) {}

  showSuccess(message:any) {
    this.toastr.success(message,'Success',);
  }

  showError(message:any) {
    this.toastr.error( message,'Error',);
  }

  showLoader() {
    this.$showLoader.emit(true)
  }

  hideLoader() {
    this.$showLoader.emit(false)
  }
}
