<div class="card d-inline-block mx-1 my-2 shadow-sm cursor">
    <img height="150px!important" style="object-fit: cover;"  [src]="property.image">
    <div  class="p-2 mt-1">
        <p class="property-type blue-text">{{property.let_off_type}}</p>
        <p class="property-title black-text">{{property.title}}</p>
        <!-- <p class="property-title black-text">3 Bedroom at Abgoga, Haatso</p> -->

        <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-50 my-2">
        <p class="property-cost black-text mb-1">{{property.price | currency:'GHC '}}</p>

    </div>
</div>

