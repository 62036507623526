import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css']
})
export class PropertyCardComponent implements OnInit {

  @Input ('property') property:any;
  @Output() propertyIdEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }


  emit(id: any){
    this.propertyIdEmitter.emit(id)
    // alert(id)
  }

}
