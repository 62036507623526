import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-kf-card',
  templateUrl: './kf-card.component.html',
  styleUrls: ['./kf-card.component.css']
})
export class KfCardComponent implements OnInit {

  @Input ('property') property:any;
  @Output() propertyIdEmitter = new EventEmitter<string>();
  address:any;
  delegates:any;
  selectedDelegate:any;
  delegateChanged:boolean = false

  constructor(private data:DataService) { }

  ngOnInit(): void {
    this.data.getUserForLocationManager('delegates')?.then(users => {
      this.delegates = (users as any).data
    })
  }


  emit(id: any){
    this.propertyIdEmitter.emit(id)
  }

  showMap(){

  }

  updateDelegate(d:any){

  }

}
