import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UiService } from './ui.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private ui: UiService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
          })

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            this.ui.showLoader()
            if (event instanceof HttpResponse) {
                setTimeout(()=>{
                    this.ui.hideLoader()
                  },100)
            }else{
              // localStorage.clear()
              // location.reload()
            }
            return event;
        }));

    }
}
