<div class="row p-0 mx-5" style="background-color: transparent;width: auto;height: fit-content;">
    <div style="background-color: white;" class="col p-0 m-0 rounded shadow-lg">
        <div style="width: 100%;">
            <form>
                <div>
                    <div class="form-group">
                       
                        <input disabled="true" style="font-size: 10px;" type="text" class="afciti-search" [value]="'Selected location: '+fullAddress"
                            (keydown.enter)="$event.preventDefault()" [placeholder]="fullAddress" autocorrect="off"
                            autocapitalize="off" spellcheck="off" type="text">
                    </div>
                    <agm-map [latitude]="latitude" (mapClick)="mapClicked($event)" [longitude]="longitude"
                        [zoom]="zoom">
                        <!-- https://maps.gstatic.com/mapfiles/ms2/micons/homegardenbusiness.png -->
                        <agm-marker *ngFor="let m of markers;let i = index" [iconUrl]="m.icon"
                            [latitude]="m.lat" [longitude]="m.lng" [label]="m.label"
                            [markerDraggable]="m.draggable">

                            <agm-info-window>
                            <div style="width: fit-content;height: fit-content;background-color: white;">
                                <div class="row justify-content-center align-items-center m-2">
                                    <i class="bi property-type-icon bi-houses blue-text col-auto"></i>
                                    <div class="col">
                                        <p class="caption col small-text">{{booking.property_data.title}}</p>
                                        <p class="col small-text">{{booking.property_data.cost}}</p>

                                    </div>
                                </div>
                               
                            </div>
                           
                          </agm-info-window>
                        </agm-marker>

                        <!-- <agm-marker [latitude]="markers[1].lat" [longitude]="markers[1].lng" [label]="markers[1].label"
                            [markerDraggable]="markers[1].draggable">

                            <agm-info-window>


                            </agm-info-window>
                        </agm-marker> -->

                    </agm-map>
                    <div *ngIf="markers[1].lat !== 0" (click)="selectLocation()" class="row cursor">
                        <div class="col"></div>
                        <div class="col-auto">
                            <p style="font-size: 10px;z-index: -100000;" pButton type="button"
                                class="text-sm px-3 py-2 blue-bg m-2 cursor caption text-button" disabled="true"
                                label="Select Meetup Location"></p>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    </div>
    <div class="col-auto ms-3">
        <i (click)="close()" style="font-size: 30px;" class="fa fa-times cursor blue-text"></i>
    </div>

</div>