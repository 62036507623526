<div class="position-relative d-inline-block m-2">
    <div style="left: 0;top: 0;width: 100%;height: 100%;" class="card d-inline-block mx-1 my-2 shadow-sm">
        <img height="250px!important" width="250px" style="object-fit: cover;" [src]="property.image">
        <div class="p-2 mt-4">
            <p class="property-type blue-text">{{property.let_off_type}}</p>
            <p class="property-title black-text">{{property.title}}</p>
            <hr style="border-color: #3B59EE!important;border-width: 1px;" class=" opacity-50 my-2">
            <p class="property-cost black-text mb-1">{{property.price | currency:'GHC '}}</p>
        </div>
        <div class="row px-4">
            <div ngbDropdown class="mt-2 me-2 col-auto row g-0 p-1  justify-content-center align-items-center"
                style="height: auto;background-color: #f9f9f9;border-radius: 30px;z-index: 10000;">
                <div id="dropdownBasic1" ngbDropdownToggle class="col-auto"
                    style="height: 22px;width: 22px;border-radius: 50%;background-color: #3B59EE;"></div>
                <div id="dropdownBasic1" ngbDropdownToggle
                    class="col px-2 justify-content-center align-items-center cursor">
                    <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">AGENT</p>
                    <p *ngIf="!delegateChanged" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">Choose
                    </p>

                </div>

                <div id="dropdownBasic1" ngbDropdownToggle class="col-auto">
                    <i class="bi bi-caret-down-fill  px-3  blue-text"></i>
                </div>
                <div *ngIf="delegates" class="pt-3 mt-1 shadow-lg"
                    style="background-color: #f9f9f9;border: none;z-index: 10000;width: fit-content;" ngbDropdownMenu
                    aria-labelledby="dropdownBasic1">
                    <div *ngFor="let d of delegates" (click)="updateDelegate(d)" ngbDropdownItem class="my-2 cursor">
                        <div class="row justify-content-center align-items-center g-3">
                            <div class="col-auto">
                                <img style="cursor: pointer;background:url('https://image-placeholder.com/images/actual-size/57x57.png');background-color: white;"
                                    [src]="d.photo" alt="" width="30" height="30" class="rounded-circle">
                            </div>
                            <div class="col">
                                <p style="font-size: 8px;" class="property-title black-text caption m-0">
                                    {{d.first_name}} {{d.last_name}}</p>
                            </div>
                            <div class="col-auto">
                                <div class="dot"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row px-3 mt-2 mb-4">
            <div class="col-auto">
                <!-- <div class="row">
                    <div class="col"> -->
                        <div (click)="showMap()" ngbDropdown #myDrop="ngbDropdown" autoClose="inside"
                            class="d-inline-block">
                            <div class="mt-2 me-1 col-auto row g-0 p-1  justify-content-center align-items-center"
                                style="height: auto;background-color: #f9f9f9;border-radius: 30px;z-index: 10000;">
                                <div id="dropdownForm1" ngbDropdownToggle class="col-auto">
                                    <i style="height: 22px;width: 22px;"
                                        class="b bi-geo-alt-fill text-center blue-text"></i>
                                </div>
                                <div id="dropdownForm1" ngbDropdownToggle
                                    class="col px-2 justify-content-center align-items-center cursor">
                                    <p style="font-size: 5px;padding: 0px;margin: 0px;" class="caption">
                                        LOCATION</p>
                                    <p *ngIf="!address" style="font-size: 9px;padding: 0px;margin: 0px;"
                                        class="caption">
                                        Choose Location
                                    </p>
                                    <p *ngIf="address" style="font-size: 9px;padding: 0px;margin: 0px;" class="caption">
                                        {{address}}
                                    </p>
                                </div>
                                <div id="dropdownForm1" ngbDropdownToggle class="col-auto me-3">
                                </div>
                            </div>
                        </div>
                    <!-- </div>
                </div> -->

            </div>
        </div>
    </div>



    <button style="left: 10px;top: 238px;font-weight: 500;font-size: 8px;"
        class="position-absolute badge rounded-pill blue-bg py-1 px-2 m-1  mt-2">{{property.availability}}</button>
</div>